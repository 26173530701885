import React, { useState, useContext, useEffect } from "react";
import DeepCleaningForm from "./DeepCleaningForm";
import StepProgressRightSide from "./StepProgressRightSide";
import { Link, useLocation } from "react-router-dom";
import CarpetCleaningForm from "./CarpetCleaningForm";
import GeneralCleaningForm from "./GeneralCleaningForm";
import IroningForm from "./IroningForm";
import SofaCleaningForm from "./SofaCleaningForm";
import { Dropdown } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { ServicesArray } from "../../Data";
import { Context } from "../../Context/Context";
import { useDispatch } from "react-redux";
import {
	UpdateService,
	UpdateDuration,
	UpdateNumberOfProfessional,
	UpdateNumberOfMaterial,
} from "../../Reducer/Reducer";
const Step1Content = () => {
	const contextData = useContext(Context);
	const [ChooseService, setChooseService] = useState("Choose Service");
	const location = useLocation();
	let data = location.pathname.split("/");
	let path = location.pathname.split("/");
	data = data[2];
	path = path[2];

	while (data.includes("-")) {
		data = data.replace("-", "");
	}
	while (path.includes("-")) {
		path = path.replace("-", " ");
	}
	const Dispatch = useDispatch();

	useEffect(() => {
		setChooseService(path);
		Dispatch(UpdateService(path));
		if (path === "general cleaning") {
			Dispatch(UpdateDuration(1));
			Dispatch(UpdateNumberOfProfessional(1));
			Dispatch(UpdateNumberOfMaterial("NO"));
		} else {
			Dispatch(UpdateDuration(""));
			Dispatch(UpdateNumberOfProfessional(""));
			Dispatch(UpdateNumberOfMaterial(""));
		}
	}, [location.pathname]);

	return (
		<div className="outerWrapperBookProfessionalForm gap-1 gap-md-4 ">
			<div className="bg-white outerWrapperLeftSideStepProgressBar">
				<p className="LabelInStepsProgressFormLeftSide mb-3">Choose Service</p>
				<label
					htmlFor="dropdown-basic2"
					className="d-flex align-items-center DropDownButtonInStepProgressForm justify-content-between"
					style={{
						borderColor: contextData.ValidationChooseService ? "red" : "",
					}}
				>
					<p className="Textcapitalize">{ChooseService}</p>
					<IoIosArrowDown className="dropDownIconInStepProgressForm" />
				</label>
				<div className="mt-3">
					<Dropdown>
						<Dropdown.Toggle
							variant="success"
							id="dropdown-basic2"
							className="d-none"
						></Dropdown.Toggle>
						<Dropdown.Menu className="outerWrapperDropDownMeuInStepProgressForm">
							{ServicesArray.map((item, index) => {
								return (
									<Dropdown.Item key={index}>
										<Link
											className="text-decoration-none text-black"
											to={`/details${item.slug}/book-professional?price=${item.price}`}
										>
											<p
												onClick={() => {
													setChooseService(item.name);
													contextData.handlerValidationChooseService(false);
												}}
											>
												{item.name}
											</p>
										</Link>
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				{data === "generalcleaning" && <GeneralCleaningForm />}
				{data === "deepcleaning" && <DeepCleaningForm />}
				{data === "carpetcleaning" && <CarpetCleaningForm />}
				{data === "ironing" && <IroningForm />}
				{data === "sofacleaning" && <SofaCleaningForm />}
			</div>
			<StepProgressRightSide />
		</div>
	);
};

export default Step1Content;
