import React from "react";
import GeneralForm from "./GeneralForm";
const IroningForm = () => {
	let CardArray = [
		{
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/IroningFormImages/ironing.webp",
			des: {
				h1: "Number Of Clothes",
				h2: "Mobile Ironing Service Collected and Delivered to You Across Dubai",
			},
			id: "i1",
			price: "200",
		},
	];
	return (
		<div>
			<GeneralForm
				name="Ironing & Folding clothes"
				image={
					process.env.REACT_APP_BASE_URL +
					"/Assets/IroningFormImages/electriciron.webp"
				}
				cardArray={CardArray}
			/>
		</div>
	);
};

export default IroningForm;
