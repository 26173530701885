import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiStarSFill } from "react-icons/ri";

const CustomerSayAboutService = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					autoplay: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					dots: false,
					arrows: false,
					autoplay: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					dots: false,
					arrows: false,
					autoplay: true,
				},
			},
		],
	};
	return (
		<div className="outerWrapperCustomerSayAboutServiceSection pb-5 pt-5">
			<Container>
				<h1 className="text-center headingServiceSection">
					What customers say about Samira salem cleaning services
				</h1>
				<p className="text-center innerHeadingServiceSection">
					Samira salem cleaning services has been rated 4.8 out of 5 based on
					962 reviews as of August 2022
				</p>
				<Slider {...settings}>
					<div className="outerWrapperSliderBox mt-5">
						<div className="d-flex align-items-center gap-2 outerWrapperStarsIcons">
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
						</div>
						<p className="TextHolderSlider">
							So amazing, so relaxing, and professional! I really wish it never
							ended! The staff are well experienced and do one of the best
							massages I’ve ever had!
						</p>
						<div className="d-flex justify-content-center mt-2 mb-2">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/Usama.webp"}
								alt="User"
								className="outerWrapperImageHolderSlider"
							/>
						</div>
						<p className="TextHolderClientNameInSlider">Usama Saim</p>
					</div>
					<div className="outerWrapperSliderBox mt-5">
						<div className="d-flex align-items-center gap-2 outerWrapperStarsIcons">
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
						</div>
						<p className="TextHolderSlider">
							tremendous! I usually have Samira salem service to clean my condo
							motive the staff may be very expert and very efficient! they have
							extremely good reductions as properly! So it's best
						</p>
						<div className="d-flex justify-content-center mt-2 mb-2">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/Kitty.webp"}
								alt="User"
								className="outerWrapperImageHolderSlider"
							/>
						</div>
						<p className="TextHolderClientNameInSlider">kitty</p>
					</div>
					<div className="outerWrapperSliderBox mt-5">
						<div className="d-flex align-items-center gap-2 outerWrapperStarsIcons">
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
						</div>
						<p className="TextHolderSlider">
							The high-quality carrier! The nurse came on time, and it took most
							effective one minute to get the entirety finished (the PCR itself
							+ amassing all the facts within the gadget). that is genuinely the
							pleasant feeling whilst you’re sitting home and nevertheless
							receiving the services!
						</p>
						<div className="d-flex justify-content-center mt-2 mb-2">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/Alina.webp"}
								alt="User"
								className="outerWrapperImageHolderSlider"
							/>
						</div>
						<p className="TextHolderClientNameInSlider">Alina</p>
					</div>
					<div className="outerWrapperSliderBox mt-5">
						<div className="d-flex align-items-center gap-2 outerWrapperStarsIcons">
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
							<RiStarSFill className="starIcons" />
						</div>
						<p className="TextHolderSlider">
							I had a facial cleaning I favored it plenty! So secure staying at
							domestic and having my very own spa at home! I had a master
							Susmita; she became very pleasant and gently endorse to reserve
							Thai carrier 👍🏻
						</p>
						<div className="d-flex justify-content-center mt-2 mb-2">
							<img
								src={process.env.REACT_APP_BASE_URL + ".Assets/Jack.webp"}
								alt="User"
								className="outerWrapperImageHolderSlider"
							/>
						</div>
						<p className="TextHolderClientNameInSlider">Jack</p>
					</div>
				</Slider>
			</Container>
		</div>
	);
};

export default CustomerSayAboutService;
