import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
function BookProfessional() {
	return (
		<div
			className="pt-4 pb-5"
			style={{
				backgroundImage: `url(${
					process.env.REACT_APP_BASE_URL + "/Assets/BookProfessional.jpg"
				})`,
				position: "relative",
			}}
		>
			<div style={{ zIndex: 2 }}>
				<div
					className="d-flex justify-content-center align-items-center Gap FontWeight"
					style={{ fontSize: "45px" }}
				>
					<p className="TextHolderBookProfessional">
						If You Need Any Service Related
					</p>
				</div>
				<div className="d-flex justify-content-center align-items-center pt-3">
					<Link
						onClick={() => {
							window.scrollTo(0, 0);
						}}
						className="text-decoration-none text-black"
						to="/details/general-cleaning/book-professional?price=120"
					>
						<Button className="BookProfessionalButton">
							Book Your Service Now
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}
export default BookProfessional;
