import React, {
	useState,
	useContext,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import { Context } from "../../Context/Context";
import SelectNumber from "../SelectNumber";
import StepProgressRightSide from "./StepProgressRightSide";
import { Link, useLocation } from "react-router-dom";
import {
	UpdateADDRESS,
	UpdateDateName,
	UpdateDateNumber,
	UpdateStart_Time,
	UpdateSuggestion,
} from "../../Reducer/Reducer";
import { useDispatch } from "react-redux";
const Step2Content = forwardRef((props, ref) => {
	const contextData = useContext(Context);
	const [TextAreaValue, setTextAreaValue] = useState("");
	const [SetTime, setSetTime] = useState("8:00:00");
	const [ErrorMessage, setErrorMessage] = useState("");
	const [Address, setAddress] = useState("");
	const [AddressValidation, setAddressValidation] = useState(false);
	const Dispatch = useDispatch();
	const location = useLocation();
	function HandlerSetTime(name) {
		setSetTime(name);
	}
	let dates = [];

	var days = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	for (let I = 0; I < Math.abs(-7); I++) {
		let date = new Date(
			new Date().getTime() - (-7 >= 0 ? I : I - I - I) * 24 * 60 * 60 * 1000
		).toLocaleString();
		date = new Date(date);
		var dayName = days[date.getDay()];
		date = date.getDate();
		let newDate = {
			number: date,
			name: dayName,
		};
		dates.push(newDate);
	}

	useImperativeHandle(ref, () => ({
		getAlert() {
			if (Address === "") {
				setErrorMessage("Field Should not empty");
				setAddressValidation(true);
			} else {
				return true;
			}
		},
	}));

	useEffect(() => {
		var days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		var d = new Date(new Date().getTime());
		var dayName = days[d.getDay()];
		var day = d.getDate();

		Dispatch(UpdateDateNumber(day));
		Dispatch(UpdateDateName(dayName));
		Dispatch(UpdateStart_Time("08:00 - 09:00"));
	}, [location.pathname]);

	return (
		<div className="outerWrapperBookProfessionalForm gap-1 gap-md-4">
			<div className="bg-white outerWrapperLeftSideStepProgressBar">
				<p className="LabelInStepsProgressFormLeftSide mt-4">
					When would you like your service?
				</p>
				<div className="mt-3">
					<SelectNumber
						service="date"
						indexKey="dateIndex"
						dateInEnglish="dateInEnglish"
						HandlerSetTime={HandlerSetTime}
						numberOfArray={dates}
					/>
				</div>
				<p className="LabelInStepsProgressFormLeftSide mt-4">
					What time would you like us to start?
				</p>
				<div className="mt-3">
					<SelectNumber
						service="time"
						indexKey="timeIndex"
						HandlerSetTime={HandlerSetTime}
						numberOfArray={[
							{ number: "08:00 - 09:00", name: "" },
							{ number: "09:00 - 10:00", name: "" },
							{ number: "10:00 - 11:00", name: "" },
							{ number: "11:00 - 12:00", name: "" },
							{ number: "12:00 - 01:00", name: "" },
						]}
					/>
				</div>
				<p className="mt-3 InnerHeadingDetailsPage TextColor">
					Your professional will arrive between {SetTime}
				</p>
				<p className="LabelInStepsProgressFormLeftSide mt-4 mb-3">
					Type Your Address
				</p>
				<input
					type="text"
					name="address"
					value={Address}
					placeholder="Address here"
					className={`${AddressValidation ? "BorderColorRed" : "BorderColor"}`}
					onChange={(e) => {
						setAddress(e.target.value);
						setErrorMessage("");
						setAddressValidation(false);
						Dispatch(UpdateADDRESS(e.target.value));
					}}
				/>
				<p className="LabelInStepsProgressFormLeftSide mt-4">
					Do you have any specific cleaning instructions?
				</p>
				<textarea
					className="textAreaInStepProgress mt-3"
					placeholder="Suggestion type here.."
					value={TextAreaValue}
					onChange={(e) => {
						setTextAreaValue(e.target.value);
						Dispatch(UpdateSuggestion(e.target.value));
						contextData.handlerInstruction(e.target.value);
					}}
				/>
				<p className="text-danger text-center">{ErrorMessage}</p>
			</div>
			<StepProgressRightSide />
		</div>
	);
});

export default Step2Content;
