import React, { useState } from "react";
import GeneralForm from "./GeneralForm";

import Scrollspy from "react-scrollspy";
import { Link } from "react-scroll";
const DeepCleaningForm = (props) => {
	const [SelectApartment, setSelectApartment] = useState(true);
	const [SelectVilla, setSelectVilla] = useState(false);
	let cardArrayApartment = [
		{
			count: 0,
			img: "",
			des: {
				h1: "Studio",
				h2: "Have your studio searching modern day with deep and thorough cleaning in a hundred and eighty mins",
			},
			id: "A1",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "1 Bedroom Apartment",
				h2: "experience a healthy and refreshing environment after we entire deep cleaning your complete home",
			},
			id: "A2",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "2 Bedroom Apartment",
				h2: "A complete system to cast off all micro organism and germs from each corner of your home.",
			},
			id: "A3",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "3 Bedroom Apartment",
				h2: "specific cleaning of your circle of relatives domestic for a hygienic and germ-unfastened domestic.",
			},
			id: "A4",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "4 Bedroom Apartment",
				h2: "In depth cleansing of each place and corner of your property with top exceptional merchandise.",
			},
			id: "A5",
			price: "200",
		},
	];
	let cardArrayVilla = [
		{
			count: 0,
			img: "",
			des: {
				h1: "1 Bedroom Villa",
				h2: "Tremendous dusting and vacuuming of the entire area through specialized experts.",
			},
			id: "v1",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "2 Bedroom Villa",
				h2: "Specified cleansing that leaves all surfaces clean , shining and dirt unfastened.",
			},
			id: "v2",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "3 Bedroom Villa",
				h2: "Thorough cleansing the usage of professional system through specialised professionals.",
			},
			id: "v3",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "4 Bedroom Villa",
				h2: "An in-detail deep cleansing consultation that will create a hygienic secure space.",
			},
			id: "v4",
			price: "200",
		},
		{
			count: 0,
			img: "",
			des: {
				h1: "5 Bedroom Villa",
				h2: "Take care of your circle of relatives home and cast off muddle and dirt in hidden regions.",
			},
			id: "v5",
			price: "200",
		},
	];
	return (
		<div>
			<Scrollspy
				items={["section-1", "section-2"]}
				currentClassName="is-current"
			>
				<div className="d-flex align-items-center gap-2 bg-white p-2">
					<Link
						spy={true}
						offset={-50}
						to="section-1"
						className={`outerWrapperSelectNumber text-decoration-none ${
							SelectApartment ? "selected" : ""
						}`}
						onClick={() => {
							setSelectApartment(true);
							setSelectVilla(false);
						}}
					>
						DeepCleaning Apartment
					</Link>
					<Link
						spy={true}
						offset={-50}
						to="section-2"
						className={`outerWrapperSelectNumber text-decoration-none ${
							SelectVilla ? "selected" : ""
						}`}
						onClick={() => {
							setSelectApartment(false);
							setSelectVilla(true);
						}}
					>
						DeepCleaning Villa
					</Link>
				</div>
			</Scrollspy>
			<div>
				<section id="section-1">
					<GeneralForm
						name="Deep Cleaning Apartment"
						image={
							process.env.REACT_APP_BASE_URL +
							"/Assets/FormImages/DeepCleaning.webp"
						}
						cardArray={cardArrayApartment}
					/>
				</section>
				<section id="section-2">
					<GeneralForm
						name="Deep Cleaning Villa"
						image={
							process.env.REACT_APP_BASE_URL +
							"/Assets/FormImages/DeepCleaningVilla.webp"
						}
						cardArray={cardArrayVilla}
					/>
				</section>
			</div>
		</div>
	);
};

export default DeepCleaningForm;
