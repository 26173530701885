import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
const TermAndCondition = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">
					Term And Condition
				</p>
				<div className="ContentBox">
					<p>Welcome to Samira salem service’s Terms and Conditions Page.</p>
					<br />
					<p>
						If making a decision to continue to browse and use our internet site
						and offerings, you are completely agreeing to Samira salem service
						phrases and situations of use, which combining with our privateness
						coverage govern Samira salem provider’s dating with you in terms of
						this website and its services. The phrases ‘Samira salem carrier’ or
						‘we’ or ‘us’ all consult with the Samira salem service.com DMCC. The
						time period ‘you’ refers to the person of our services or the viewer
						of our internet site.
					</p>
					<br />
					<br />
					<p>
						the usage of this internet site is subject however now not
						constrained to the underneath terms of use:
					</p>
					<ol>
						<li>
							The content of the pages of this internet site is for the
							explanation and implementation of our services. it's far issue to
							alternate without notice.
						</li>
						<li>
							We use cookies to monitor browsing preferences. If you allow
							cookies to be used, the relevant personal information may be
							stored by us for use in fields we determine.
						</li>
						<li>
							Samira salem service keeps the right to change, amend, cancel or
							make exceptions to the terms and conditions listed here. Please
							check this terms and conditions page from time to time. By using
							our services, you acknowledge that you agree with our terms and
							conditions.
						</li>
						<li>
							No partnership, joint venture, or agency is created as a result of
							the Terms and Conditions and you do not have any authority of any
							kind to link Samira salem service any respect whatsoever. Samira
							salem service will not be liable for any failure to perform its
							obligations hereunder where such failure results arising from any
							reason beyond Samira salem service's reasonable control,
							including, without limitation, electronic, mechanical or
							communications failure or degradation.
						</li>
						<li>
							All materials on the website are owned via or certified to us.
							This cloth consists of, however is not restrained to layout,
							layout, look, look, pics, and such. replica is exactly prohibited
							apart from in accordance with the copyright note, which paperwork
							a part of those terms and situations.
						</li>
						<li>
							You deliver your consent that you'll no longer do unauthorized use
							of the materials and facts on the website.
						</li>
						<li>
							We may additionally encompass links to other websites. these
							external hyperlinks are provided for your convenience to offer in
							addition records. We aren't answerable for the accuracy and
							correctness of the external link content.
						</li>
						<li>
							Your use of this internet site and any dispute springing up out of
							such use of the website is problem to the neighborhood legal
							guidelines of UAE foremost Courts.
						</li>
						<li>
							when you make a reserving via Samira salem provider, we can assist
							facilitate the offerings in your easy. as soon as the clean is
							performed the prices you pay to our cleaners/cleaning organization
							companions are non-refundable (unless there may be a
							organisation-prompted violation). The pricing scheme may be up to
							date by way of the corporation on a going-ahead basis. you are
							accountable for any and all taxes (except taxes primarily based on
							our profits).
						</li>
						<li>
							Samira salem service can also exchange quotes and charges every so
							often by means of posting the changes on the Samira salem provider
							internet site 10 days earlier, however with no develop word
							obligatory for transient promotions or any changes that result in
							the discount of costs and fees
						</li>
						<li>
							Samira salem provider can not be held accountable for the illegal
							acts of cleaners/cleansing employer companions and users.
						</li>
						<li>
							Samira salem provider assessments the backgrounds of
							cleaners/cleansing organization partners both immediately and
							circuitously via 0.33-celebration history test offerings. however,
							each person have to exercising common experience and warning to
							shield its private safety and property, similar to you'll while
							confronted with any person who you do now not recognise. The users
							agree to preserve Samira salem service free from the liability for
							any damages and/or lacking gadgets throughout or after the use of
							the cleaner/cleaning organization accomplice services via Samira
							salem service.
						</li>
						<li>
							Samira salem carrier will rate you in your finalized bookings via
							the fee technique exact at the website (e.g. a credit card or
							coins). in case you appear to pay any relevant charges with your
							credit score card, we may also seek pre-authorisation of your
							credit score card account previous to your purchase.
						</li>
						<li>
							in case you pick to pay the fee via your credit score card, you,
							because the cardholder, have to keep a copy of transaction facts
							and our regulations and policies.
						</li>
						<li>
							you're liable for keeping the confidentiality of all the records
							on your account.
						</li>
						<li>
							if you need to cancel your booking, you're required to offer a
							minimal of 24 hours’ be aware of cancellation. Notification should
							be in written layout and to be sent to wecare@Samira salem
							carrier.com. We reserve the right to levy a certain charge up to
							AED 100 to cowl any relevant administrative costs.
						</li>
						<li>
							clients the use of the website who're minor/below the age of 18
							shall now not sign in as a consumer of the website and shall now
							not transact on or use the internet site.
						</li>
						<li>
							Samira salem provider has the right to apply penalties to its
							cleansing service provider partners within the case of harm to the
							houses or house interiors on the way to defend the network.
							however, what happens to the consumer's property and residence
							interiors throughout the cleaning session is solely the client's
							responsibility. Samira salem carrier is not answerable for homes
							and interiors all through the method of stepping into the house or
							getting out of the house. Cleaners/cleansing employer partners or
							Samira salem service aren't obliged to cover any damage(s)
							triggered in the course of the cleansing session.
						</li>
						<li>
							If any provision of this phrases and conditions is found to be
							unenforceable or/and invalid, that provision will be restricted to
							the minimum volume essential so that the terms and conditions
							shall otherwise be saved in complete force and impact. Samira
							salem carrier may additionally transfer, assign or delegate the
							phrases and situations and its rights and duties without consent.
						</li>
						<li>
							Samira salem provider will not change with or provide any services
							to OFAC and sanctioned international locations.
						</li>
						<li>
							Samira salem carrier will ship messages via Whatsapp, SMS, e-mail
							or notifications as approach of conversation for the functions of
							inclusive of but not limited to appointment confirmation,
							appointment updates, and marketing.
						</li>
						<li>
							Samira salem provider is a reserving platform that acts as an
							aggregator for distinct kinds of home services. The offerings are,
							inclusive of but aren't confined to, domestic cleansing, PCR take
							a look at at domestic, salon services, maintenance, vehicle wash,
							laundry and dry cleansing, upholstery cleaning, disinfection
							offerings, pest manipulate. Samira salem carrier has contracts
							with 3rd birthday party companions which might be licensed with
							the aid of the relevant government.
						</li>
						<li>
							For the PCR service, Samira salem provider is most effective an
							aggregator of DHA certified healthcare facilities and does no
							longer shop any private healthcare records and records.
						</li>
						<li>
							All facts concerning user clinical data is personal in nature and
							it is saved through the DHA-certified healthcare facilities.
						</li>
						<li>
							The clinical liability is completely on the healthcare centers.
							The facilities are the accountable parties for turning in pleasant
							healthcare to the customers along with the garage in their private
							clinical statistics wherein relevant.
						</li>
					</ol>
					<br />
					<br />
					<b>method of charge, Card types customary and foreign money</b>
					<p>
						We accept payments online the use of Visa, and credit card
						credit/debit playing cards in AED (or every other foreign money
						agreed).
					</p>
					<br />
					<br />
					<b>Refund/Return policy</b>
					<p>Refunds may be accomplished best thru the unique Mode of fee</p>
					<br />
					<br />
					<b>quality guarantee software / nice manipulate policy</b>
					<br />
					<br />
					<p>
						We conduct random satisfactory inspections on a regular foundation
						to check the first-class of our team participants' paintings even as
						they're on obligation.
					</p>
					<br />
					<p>
						right here are the 3 methods we use in maintaining the high-quality
						exams:
					</p>
					<br />
					<ol>
						<li>
							Unannounced go to - To make sure cleaners are well uniformed and
							acting their obligations very well and absolutely.
						</li>
						<br />
						<li>
							On-website Inspection - A stroll-via with the purifier and/or the
							client all through ordinary enterprise hours.
						</li>
						<br />
						<li>
							follow-up – A brief telephone name by our customer support
							marketers after your session has been finished.
						</li>
						<br />
					</ol>
					<br />
					<b>Reporting occurred issues</b>
					<br />
					<ul>
						<li>lacking/ stolen objects: inside forty eight hours.</li>
						<li>damaged items: inside 24 hours.</li>
					</ul>
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default TermAndCondition;
