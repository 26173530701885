import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ServicesArray } from "../Data";
function Footer() {
	return (
		<Container fluid>
			<Row className="text-white  pb-3 justify-content-center outerWrapperFooter">
				<Col lg={10} className="d-flex flex-wrap justify-content-between gap-2">
					{ServicesArray.map((items, index) => {
						return (
							<Link
								key={index}
								onClick={() => window.scrollTo(0, 0)}
								className="text-decoration-none text-white"
								to={`/details${items.slug}`}
							>
								<p className="footer_text">{items.name}</p>
							</Link>
						);
					})}
				</Col>
				<Col lg={10} className="justify-content-center ">
					<hr className="mt-4" />
				</Col>
				<Col
					lg={10}
					className="d-flex flex-wrap pt-2 pb-2 justify-content-sm-between justify-content-center "
				>
					<div className="d-flex gap-3">
						<Link
							onClick={() => {
								window.scrollTo(0, 0);
							}}
							className="text-decoration-none text-white"
							to="/faq"
						>
							F.A.Q
						</Link>

						<Link
							onClick={() => {
								window.scrollTo(0, 0);
							}}
							className="text-decoration-none text-white"
							to="/term-and-conditions"
						>
							Term
						</Link>

						<Link
							className="text-decoration-none text-white"
							to="/privacy"
							onClick={() => {
								window.scrollTo(0, 0);
							}}
						>
							Privacy
						</Link>
					</div>
					<div>
						<p className="text-center textHolderFooter">
							Designed and developed by zack technologies
						</p>
					</div>
					<div className="d-flex gap-3">
						<p>
							<a
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none text-white"
								href=""
							>
								Facebook
							</a>
						</p>
						<p>
							<a
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none text-white"
								href=""
							>
								Twitter
							</a>
						</p>
						<p>
							<a
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none text-white"
								href=""
							>
								Instagram
							</a>
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
export default Footer;
