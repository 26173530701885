import React from "react";
import { Container, Accordion, Tab, Tabs } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
const Faq = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">FAQ</p>
				<div className="container-term">
					<Tabs
						defaultActiveKey="home"
						id="uncontrolled-tab-example"
						className="bg-white mb-0 justify-content-center TabHeader"
					>
						<Tab eventKey="home" title="General">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										Am i able to request a specific cleansing professional?
									</Accordion.Header>
									<Accordion.Body>
										you could request a particular cleaner through the App or
										the website. but, inside the occasion that your requested
										cleaner isn't available, you could still book any other
										purifier with the identical score. We promise to do our
										excellent to send you the equal cleanser whilst you e-book
										one-time or advert-hoc appointments, however we can't assure
										their availability as our top-notch team contributors get
										booked up right away through weekly customers.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										How does the service charge work?
									</Accordion.Header>
									<Accordion.Body>
										The provider charge lets in us to beautify your experience
										with Samira salem service, such as including new features
										and offering terrific customer service. when it comes to how
										lots you are charged, you may continually be able to see the
										exact amount at checkout
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>
										What is Samira salem service.com?
									</Accordion.Header>
									<Accordion.Body>
										Samira salem service.com is the biggest on-line marketplace
										in GCC that connects expert and pinnacle-rated cleansing
										provider carriers with human beings searching out cleansing
										services. we're presently working in the UAE, KSA, Kuwait,
										Qatar, Oman and, Bahrain. In different words, we are a
										leisure time improver by using giving you all of your loose
										time returned whilst our professionals deal with your home
										cleansing. We agree with that everyone has the right to find
										a depended on cleaner in less than 1 minute,
										hassle-unfastened! And all it takes is the followings:
										<br />
										<br />
										<br />- Specify your cleansing details - choose the desired
										date and time - enter your touch and address info. - Pay
										thru credit score card or coins relying to your choice
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>
										Do you supply your very own cleansing materials?
									</Accordion.Header>
									<Accordion.Body>
										if you choose us to bring our cleansing substances, our
										group member will include a Samira salem service cleansing
										package containing the subsequent gadgets: cleaning
										merchandise floor cleaner toilet cleanser Multi-cause
										cleanser Glass purifier Cream cleaner timber furniture
										polish cleaning gadget Vacuum cleanser Mop Bucket & spinner
										Microfiber fabric Sponge fabric Scouring pad be aware: There
										might be an additional AED 10 consistent with hour fee in
										case you request the cleaning substances.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>
										What are the nationalities of your cleaning specialists?
									</Accordion.Header>
									<Accordion.Body>
										maximum of our cleaners are from the Philippines, Nepal and
										Sri lanka and other nationalities. All properly-trained and
										trustworthy.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>
										Can i ask for multiple cleaning expert?
									</Accordion.Header>
									<Accordion.Body>
										yes. booking for extra cleaners to get the job done is easy.
										you could request up to four cleaners in total. certainly
										specify the wide variety of experts you want whilst
										reserving the appointment.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="6">
									<Accordion.Header>
										Am i able to e book the identical cleaning expert greater
										than once?
									</Accordion.Header>
									<Accordion.Body>
										Ees, you could. 90% of our clients have a weekly cleansing
										with the identical cleanser. it is easier for us to provide
										you with the same cleanser when you have a ordinary
										appointment (normally weekly or bi-weekly). you may revel in
										the lifetime discount once beneath the habitual carrier.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="7">
									<Accordion.Header>
										What are the cleansing materials I want to offer?
									</Accordion.Header>
									<Accordion.Body>
										If you pick to opt for offering materials yourself, we
										advise you to have these in your private home: Vacuum
										cleanser Mop Multi-purpose cleaning Spray general motive
										Polish floor purifier Bleach Limescale Remover Micro Fibre
										Cloths fixtures Polish
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>
						<Tab eventKey="profile" title="Payment">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										What are my fee alternatives?
									</Accordion.Header>
									<Accordion.Body>
										Samira salem service offers three fee methods: cash credit
										score card Samira salem service credit We receive Visa and
										credit card. we will ship a price request in your financial
										institution and your card might be mechanically charged once
										the carrier has been finished.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item
									eventKey="1"
									className="AccordionHeaderInBottom"
								>
									<Accordion.Header className="AccordionHeaderInBottom">
										What is the Samira salem service Credit?
									</Accordion.Header>
									<Accordion.Body>
										shopping the Samira salem service credit score may be very
										easy, certainly comply with the steps beneath: Log in using
										your phone range. as soon as logged in: cellular
										application: please faucet the 3 vertical lines on the top
										left nook of your display to expose the slide menu. internet
										site: at the top right corner of the display screen, please
										click on your call to show the drop-down menu. pick Samira
										salem service credit and click top-UP credit score.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item
									eventKey="2"
									className="AccordionHeaderInBottom"
								>
									<Accordion.Header className="AccordionHeaderInBottom">
										How to check the credit balance?
									</Accordion.Header>
									<Accordion.Body>
										Login the usage of your telephone range. as soon as logged
										in: cellular software: please tap the 3 vertical strains on
										the higher left corner of your display screen to reveal the
										slide menu. website: at the higher right nook of the display
										screen, please click your call to reveal the drop-down menu.
										pick Samira salem service credit score and click See info..
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item
									eventKey="3"
									className="AccordionHeaderInBottom"
								>
									<Accordion.Header className="AccordionHeaderInBottom">
										How do I register my card details?
									</Accordion.Header>
									<Accordion.Body>
										Registering your card online or through the app is easy. All
										you need to do is to comply with the stairs underneath:
										Login the usage of your phone number. as soon as logged in:
										mobile software: please faucet the 3 vertical strains on the
										top left corner of your screen to reveal the slide menu
										website: at the top right nook of the screen, please click
										your call to reveal the drop-down menu. select Settings. go
										to manipulate credit score playing cards. click on add new.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>
						<Tab eventKey="contact" title="Booking">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										how to e book a domestic cleansing appointment?
									</Accordion.Header>
									<Accordion.Body>
										Reserving an appointment on line or thru the app is easy.
										just genuinely follow the steps below. go to www.Samira
										salem service or use the App. choose home cleansing then
										click e book NOW. pick the frequency then click subsequent.
										One-time Bi-weekly Weekly Specify your cleansing details
										then click on subsequent. length wide variety of cleaners
										cleansing materials (if needed) upload precise instructions
										if wanted pick out the preferred date and time then click on
										next. pick out the preferred payment approach then click on
										entire.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										How do I redeem a voucher code?
									</Accordion.Header>
									<Accordion.Body>
										You can enter your cut price code in step 4 of the checkout
										manner below upload VOUCHER CODE. After coming into the
										voucher code, click follow. keep in mind that every voucher
										has its very own terms and situations that need to be met
										for the cut price to be valid for your order. After the
										voucher has been correctly processed, the discounted
										quantity can be deducted from the full bill quantity.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>
										Am i able to upload unique commands while booking?
									</Accordion.Header>
									<Accordion.Body>
										Yes, you can. even as on the reserving page, you may be
										requested when you have any specific instructions. if you
										finished reserving the appointment and ignored adding the
										commands, no issues! you could nonetheless replace your
										reserving information by using following the stairs under:
										Log in the use of your telephone wide variety. once logged
										in: cell application: please faucet the three vertical lines
										on the upper left corner of your display to reveal the slide
										menu. website: on the top right corner of the display
										screen, please click on your call to show the drop-down
										menu. pick out Appointments. once beneath the upcoming
										appointment web page, click the desired appointment to be
										updated. inside the commands field, click on “upload.”
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>
										I forgot to feature a unique instruction. What ought to I
										do?
									</Accordion.Header>
									<Accordion.Body>
										No issues, we're happy to help you. Please share your
										guidance at the least four hours prior to the appointment
										and we can make certain to share it with the cleaner. you
										may reach us at wecare@Samira salem service.com or via our
										live chat support line.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>
										I booked the appointment with the wrong address, what need
										to I do?
									</Accordion.Header>
									<Accordion.Body>
										Kindly attain us on our live chat guide line or at
										wecare@Samira salem service.com. Our patron care
										representatives will help you replace your address right
										away.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>
										How am i able to extend my cleansing?
									</Accordion.Header>
									<Accordion.Body>
										If the period you booked is not enough, all you have to do
										is to reach us at wecare@Samira salem service.com or through
										our stay chat help. Our experts will take a look at the
										purifier’s schedule to see if she is to be had for an
										extension.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="6">
									<Accordion.Header>
										what is my reserving reference number?
									</Accordion.Header>
									<Accordion.Body>
										You may easily find your reference wide variety by using
										following the steps below: Log in the use of your smartphone
										wide variety. as soon as logged in: cellular utility: please
										faucet the 3 vertical lines on the higher left nook of your
										display to reveal the slide menu. website: on the higher
										right corner of the screen, please click on your call to
										show the drop-down menu. pick out Appointments to peer the
										reference code.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>
					</Tabs>
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default Faq;
