import React, { createContext, useState } from "react";
export const Context = createContext();

export const ContextProvider = ({ children }) => {
	const [TotalPrice, setTotalPrice] = useState(120);
	const [DisplayLoader, setDisplayLoader] = useState(false);
	const [ValidationChooseService, setValidationChooseService] = useState(false);
	const [ValidationChooseAddress, setValidationChooseAddress] = useState(false);
	const [UpdateValue, setUpdateValue] = useState(false);
	const [Instructions, setInstructions] = useState("");
	const [UpdateComponent, setUpdateComponent] = useState(false);
	const [SerViceDetails, setSerViceDetails] = useState({
		Service: "",
		Duration: "",
		Number_of_Professionals: "",
		Material: "",
		Date: { number: "", name: "" },
		Start_Time: "",
		ADDRESS: "",
		serviceDetail: [],
		suggestion: "",
		totalPrice: "",
	});
	function handlerDisplayLoader(toggle) {
		setDisplayLoader(toggle);
	}
	function handlerValidationChooseService(toggle) {
		setValidationChooseService(toggle);
	}
	function handlerValidationChooseAddress(toggle) {
		setValidationChooseAddress(toggle);
	}
	function handlerTotalPrice(price) {
		setTotalPrice(price);
	}
	function handlerInstruction(data) {
		setInstructions(data);
	}
	function handlerUpdateValue(toggle) {
		setUpdateValue(toggle);
	}
	function handleSerViceDetails(data) {
		setSerViceDetails(data);
	}

	return (
		<Context.Provider
			value={{
				handlerDisplayLoader,
				DisplayLoader,
				handlerValidationChooseService,
				ValidationChooseService,
				handlerValidationChooseAddress,
				ValidationChooseAddress,
				handlerTotalPrice,
				TotalPrice,
				handlerInstruction,
				Instructions,
				handlerUpdateValue,
				UpdateValue,
				handleSerViceDetails,
				SerViceDetails,
				UpdateComponent,
			}}
		>
			{children}
		</Context.Provider>
	);
};
