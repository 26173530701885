import React from "react";
import { Carousel } from "react-bootstrap";
import { ServicesArray } from "../Data";
import { Link } from "react-router-dom";
function CarouselSlider() {
	return (
		<div>
			<Carousel fade indicators={false}>
				{ServicesArray.map((item, index) => {
					return (
						<Carousel.Item interval={1000} key={index}>
							<Link
								onClick={() => window.scrollTo(0, 0)}
								className="text-decoration-none text-white"
								to={`/details${item.slug}`}
							>
								<div className="outerWrapperCarouselImages">
									<img
										className="d-block w-100"
										src={item.detail.bannerImage}
										alt="First slide"
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
										}}
									/>
								</div>
								<Carousel.Caption>
									<h3 className="CarouselHeading">World's Best {item.name}</h3>
									<p className="CarouselDescription"></p>
								</Carousel.Caption>
							</Link>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</div>
	);
}
export default CarouselSlider;
