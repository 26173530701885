import React, { useRef, useEffect, useState } from "react";
import BookProfessional from "../Component/BookProfessional";
import CarouselSlider from "../Component/CarouselSlider";
import CustomerSayAboutService from "../Component/CustomerSayAboutService";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import ProjectCompleted_Clients_Rating from "../Component/ProjectCompleted_Clients_Rating";
import ReasonToLoveService from "../Component/ReasonToLoveService";
import Services from "../Component/Services";
import { useLocation } from "react-router-dom";
const Home = () => {
	const [Sticky, setSticky] = useState(null);
	const [DisplayHeader, setDisplayHeader] = useState(false);
	const SetStickyPosition = () => {
		if (window.scrollY >= 200) {
			setSticky("0");
			setDisplayHeader(true);
		} else {
			setSticky(null);
			setDisplayHeader(false);
		}
	};
	window.addEventListener("scroll", SetStickyPosition);
	const myRef = useRef(null);
	const executeScroll = () => myRef.current.scrollIntoView();
	const location = useLocation();
	useEffect(() => {
		if (location.hash === "#services") {
			executeScroll();
		}
	}, [location.hash]);
	return (
		<div>
			<Header executeScroll={executeScroll} />
			<div
				style={{
					position: "sticky",
					top: Sticky,
					zIndex: "3",
				}}
			>
				{DisplayHeader && <Header executeScroll={executeScroll} />}
			</div>
			<CarouselSlider />
			<ProjectCompleted_Clients_Rating />
			<div ref={myRef}>
				<Services />
			</div>
			<BookProfessional />
			<ReasonToLoveService />
			<CustomerSayAboutService />
			<Footer />
		</div>
	);
};

export default Home;
