import React from "react";
import SelectNumber from "../SelectNumber";
const GeneralCleaningForm = () => {
	function HandlerSetTime(name) {}

	return (
		<div>
			<p className="LabelInStepsProgressFormLeftSide">
				How many hours do you need your professional to stay?
			</p>
			<div className="mt-3">
				<SelectNumber
					HandlerSetTime={HandlerSetTime}
					service="hours"
					indexKey="hourIndex"
					numberOfArray={[
						{ number: 1, name: "" },
						{ number: 2, name: "" },
						{ number: 3, name: "" },
						{ number: 4, name: "" },
						{ number: 5, name: "" },
					]}
				/>
			</div>
			<p className="LabelInStepsProgressFormLeftSide mt-4">
				How many professionals do you need?
			</p>
			<div className="mt-3">
				<SelectNumber
					HandlerSetTime={HandlerSetTime}
					service="professional"
					indexKey="professionalIndex"
					numberOfArray={[
						{ number: 1, name: "" },
						{ number: 2, name: "" },
						{ number: 3, name: "" },
						{ number: 4, name: "" },
					]}
				/>
			</div>
			<p className="LabelInStepsProgressFormLeftSide mt-4">
				Do you require cleaning materials?
			</p>
			<div className="mt-3">
				<SelectNumber
					HandlerSetTime={HandlerSetTime}
					service="material"
					indexKey="materialIndex"
					numberOfArray={[
						{ number: "No.I heave them", name: "" },
						{ number: "Yes,please", name: "" },
					]}
				/>
			</div>
		</div>
	);
};

export default GeneralCleaningForm;
