import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
const Privacy = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">
					Term And Condition
				</p>
				<div className="ContentBox">
					<p>
						we are dedicated to safeguarding the privateness of our internet
						site visitors; this coverage sets out how we will treat your
						personal records. Our internet site makes use of cookies. by the
						usage of our website and agreeing to this policy, you consent to our
						use of cookies according with the phrases of this policy. What
						records will we collect? We may additionally gather, store and use
						the following forms of personal statistics:
					</p>
					<br />
					<ol>
						<li>
							information approximately your computer and approximately your
							visits to and use of this website (inclusive of your IP deal with,
							geographical area, browser kind and model, running system,
							referral source, length of visit, web page views and internet site
							navigation);
						</li>
						<li>
							records relating to any transactions carried out between you and
							us on or in terms of this website, including information regarding
							any purchases you are making of our items or services
						</li>
						<li>
							statistics which you provide to us for the reason of registering
							with us (together with your name, address and email deal with);
						</li>
						<li>
							facts that you offer to us for the purpose of subscribing to our
							website services, e-mail notifications and/or newsletters which
							includes your name and email cope with;
						</li>
						<li>any other facts that you select to ship to us; and</li>
						<li>other information.</li>
					</ol>
					<br />
					<p>
						earlier than you disclose to us the private information of any other
						character, you have to gain that character’s consent to both the
						disclosure and the processing of that personal records in accordance
						with the phrases of this privacy coverage.
					</p>
					<br />
					<br />
					<p>
						All credit score/debit playing cards info and in my opinion
						identifiable statistics will no longer be stored, offered, shared,
						rented or leased to any third events.
					</p>
					<br />
					<br />
					<br />
					<b>1. Cookies</b>
					<br />
					<p>
						A cookie is a file containing an identifier (a string of letters and
						numbers) that is sent by an internet server to an internet browser
						and is stored by the browser. The identifier is then despatched back
						to the server every time the browser requests a web page from the
						server. This allows the web server to identify and music the net
						browser. We may also use both “consultation” cookie and “continual”
						cookies at the website. session cookies may be deleted from your pc
						while you close your browser. persistent cookies will stay stored on
						your laptop until deleted, or till they reach a special expiry date.
						we can use the session cookies to: keep song of you while you
						navigate the website; keep song of your bookings; save you fraud and
						increase internet site security; and [other uses]. we will use the
						continual cookies to: allow our internet site to understand you
						while you go to; hold music of your possibilities when it comes to
						your use of our internet site; and different makes use of. We use
						Google Analytics to analyze using this internet site. Google
						Analytics generates statistical and different statistics about
						internet site use by way of cookies, which can be stored on users’
						computers. The information generated regarding our website is used
						to create reports about using the internet site. Google will keep
						this data. Google’s privacy policy is to be had at:
						http://www.google.com/privacypolicy.html. Our fee offerings vendors
						can also send you cookies
					</p>
					<br />
					<br />
					<b>2. Using your personal information</b>
					<br />
					<p>
						Personal statistics submitted to us thru this internet site could be
						used for the purposes detailed on this privateness coverage or in
						applicable elements of the website. We can also use your private
						statistics to:
					</p>
					<br />
					<ol>
						<li>administer the website;</li>
						<li>
							enhance your surfing experience with the aid of personalizing the
							internet site;
						</li>
						<li>
							permit your use of the offerings available on the internet site;
						</li>
						<li>supply to you services bought via the internet site;</li>
						<li>
							ship statements and invoices to you, and acquire bills from you;
						</li>
						<li>
							ship you trendy (non-advertising) commercial communications;
						</li>
						<li>ship you email notifications that you have mainly asked;</li>
						<li>
							ship you our publication and different marketing communications
							referring to our business which we think may be of interest to
							you, by means of post or, where you've got specially agreed to
							this, by using e mail or comparable era (and you can tell us at
							any time in case you no longer require advertising
							communications);
						</li>
						<li>
							deal with enquiries and proceedings made via or about you
							concerning to the website;
						</li>
						<li>keep the website comfortable and prevent fraud;</li>
						<li>
							affirm compliance with the phrases and situations governing the
							use of the internet site (inclusive of tracking personal messages
							sent via our internet site personal messaging carrier); and
						</li>
						<li>other uses.</li>
					</ol>
					<br />
					<p>
						where you publish private statistics for book on our website, we can
						post and in any other case use that records in accordance with the
						licence you supply to us. we can no longer, with out your specific
						consent, offer your non-public facts to any 0.33 parties for the
						reason of direct marketing. All our internet site credit score card
						monetary transactions are handled thru our payment offerings issuer,
						Checkout. you could review their privacy coverage at
						https://www.checkout.com/criminal/privateness-policy. we are able to
						share statistics with Checkout only to the extent necessary for the
						functions of processing bills you're making thru our website,
						refunding such bills and dealing with lawsuits and queries regarding
						such bills and refunds. Samira salem carrier will neither keep any
						debit/credit card info of users nor pass any debit/credit score card
						info to third events.
					</p>
					<br />
					<br />
					<br />
					<b>3. Disclosures</b>
					<br />
					<p>
						We may additionally disclose your non-public facts to any of our
						personnel, officials, dealers, providers or subcontractors insofar
						as fairly necessary for the purposes set out on this privacy
						coverage. We may disclose your private statistics to any member of
						our organization of organizations (this means our subsidiaries, our
						remaining retaining employer and all its subsidiaries) insofar as
						reasonably vital for the purposes set out on this privateness
						coverage. similarly, we might also disclose your personal records:
					</p>
					<br />
					<ol>
						<li>
							to the quantity that we are required to accomplish that with the
							aid of regulation;
						</li>
						<li>
							in reference to any ongoing or prospective prison complaints;
						</li>
						<li>
							in an effort to establish, exercising or defend our criminal
							rights (which includes supplying information to others for the
							functions of fraud prevention and decreasing credit score danger);
						</li>
						<li>
							to any person who we reasonably trust may also apply to a court
							docket or other ready authority for disclosure of that personal
							records wherein, in our affordable opinion, such court or
							authority would be moderately probably to order disclosure of that
							private statistics.
						</li>
					</ol>
					<br />
					<p>
						except as provided in this privateness coverage, we are able to not
						offer your facts to 1/3 parties.
					</p>
					<br />
					<br />
					<br />
					<b>4. International data transfers</b>
					<br />
					<p>
						information that we collect can be stored and processed in and
						transferred between any of the nations in which we perform in order
						to allow us to apply the records in accordance with this privateness
						coverage. further, private records which you post for e-book on the
						internet site can be posted at the net and can be to be had, via the
						internet, round the world. We can not prevent the use or misuse of
						such records with the aid of others. You expressly conform to such
						transfers of private records.
					</p>
					<br />
					<br />
					<br />
					<b>5. Security of your personal information</b>
					<p>
						we are able to take reasonable technical and organisational
						precautions to prevent the loss, misuse or alteration of your
						personal statistics. we can shop all the non-public facts you
						provide on our comfy (password- and firewall-blanketed) servers. All
						digital transactions entered into through the website could be
						blanketed through encryption technology. You well known that the
						transmission of facts over the internet is inherently insecure, and
						we cannot assure the safety of records despatched over the internet.
						Samira salem carrier takes appropriate steps to make sure
						information privateness and safety along with thru various hardware
						and software methodologies. however, Samira salem carrier cannot
						assure the security of any facts that is disclosed on-line.
					</p>
					<br />
					<br />
					<br />
					<b>6. Policy amendments</b>
					<br />
					<p>
						We can also update this privateness policy from time to time by
						means of posting a brand new version on our website. You need to
						check this web page on occasion to ensure you are satisfied with any
						adjustments. We might also notify you of changes to our privacy
						policy by means of e-mail.
					</p>
					<br />
					<br />
					<br />
					<b>7. Your rights</b>
					<br />
					<p>
						you may coach us to provide you with any personal records we keep
						about you. Provision of such records can be problem to:
					</p>
					<br />
					<ol>
						<li>the charge of a rate (currently constant at GBP £5); and</li>
						<li>
							the supply of appropriate proof of your identity (for this
							purpose, we will generally receive a photocopy of your passport
							licensed by a solicitor or bank plus an original copy of a
							application invoice showing your present day address).
						</li>
					</ol>
					<br />
					<p>
						We can also withhold such non-public information to the extent
						authorized with the aid of regulation. you can teach us now not to
						process your non-public records for advertising and marketing
						purposes, by way of sending an email to us at wecare@Samira salem
						service.com. In practice, you may generally both expressly agree
						earlier to our use of your private information for advertising and
						marketing functions, or we will offer you with an possibility to
						choose out of using your non-public records for advertising
						purposes.
					</p>
					<br />
					<br />
					<br />
					<b>8. Third party websites</b>
					<br />
					<p>
						The internet site incorporates links to different web sites. We are
						not accountable for the privateness regulations or practices of
						third birthday celebration web sites.
					</p>
					<br />
					<br />
					<br />
					<b>9. Updating information</b>
					<br />
					<p>
						Please let us realize if the private data which we maintain about
						you wishes to be corrected or up to date.
					</p>
					<br />
					<br />
					<br />
					<b>10. Data Collection</b>
					<br />
					<p>
						We accumulate records containing your telephone wide variety, e-mail
						id, domestic cope with, latitude, and longitude. This statistics is
						furnished to us whilst you want to make a booking. We need to
						contact you and locate your place to offer home offerings. In case
						you want this records deleted, send us an e-mail to wecare@Samira
						salem provider.com requesting the statistics deletion.
					</p>
				</div>
			</Container>
			<Footer />
		</div>
	);
};
export default Privacy;
