import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import StepProgressForm from "../Component/StepsProgress/StepProgressForm";
const BookProfessional = () => {
	return (
		<div>
			<Header />
			<Container fluid="lg" className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">
					Book Professional
				</p>
				<StepProgressForm />
			</Container>
			<Footer />
		</div>
	);
};

export default BookProfessional;
