export let AreaArray = [
	{ name: "Abu Hail" },
	{ name: "Al Awir First" },
	{ name: "Al Awir Second" },
	{ name: "Al Bada" },
	{ name: "Al Baraha" },
	{ name: "Al Barsha First" },
	{ name: "Al Barsha Second" },
	{ name: "Al Barsha South First" },
	{ name: "Al Barsha South Second" },
	{ name: "Al Barsha South Third" },
	{ name: "Al Barsha Third" },
	{ name: "Al Buteen" },
	{ name: "Al Dhagaya" },
	{ name: "Al Garhoud" },
	{ name: "Al Guoz Fourth" },
	{ name: "Al Hamriya, Dubai" },
	{ name: "Al Hamriya Port" },
	{ name: "Al Hudaiba" },
	{ name: "Al Jaddaf" },
	{ name: "Al Jafiliya" },
	{ name: "Al Karama" },
	{ name: "Al Khabisi" },
	{ name: "Al Khwaneej First" },
	{ name: "Al Khwaneej Second" },
	{ name: "Al Kifaf" },
	{ name: "Al Mamzar" },
	{ name: "Al Manara" },
	{ name: "Al Merkad" },
	{ name: "Al Mina" },
	{ name: "Al Mizhar First" },
	{ name: "Al Mizhar Second" },
	{ name: "Al Muraqqabat" },
	{ name: "Al Murar" },
	{ name: "Al Sabkha" },
	{ name: "Al Muteena" },
	{ name: "Al Nahda First" },
	{ name: "Al Nahda Second" },
	{ name: "Al Quoz First" },
	{ name: "Al Quoz Industrial First" },
	{ name: "Al Quoz Industrial Fourth" },
	{ name: "Al Quoz Industrial Second" },
	{ name: "Al Quoz Industrial Third" },
	{ name: "Al Quoz Second" },
	{ name: "Al Quoz Third" },
	{ name: "Al Qusais First" },
	{ name: "Al Qusais Industrial Fifth" },
	{ name: "Al Qusais Industrial First" },
	{ name: "Al Qusais Industrial Fourth" },
	{ name: "Al Qusais Industrial Second" },
	{ name: "Al Qusais Industrial Third" },
	{ name: "Al Qusais Second" },
	{ name: "Al Qusais Third" },
	{ name: "Al Raffa" },
	{ name: "Al Ras" },
	{ name: "Al Rashidiya" },
	{ name: "Al Rigga" },
	{ name: "Al Safa First" },
	{ name: "Al Safa Second" },
	{ name: "Al Safouh First" },
	{ name: "Al Safouh Second" },
	{ name: "Al Satwa" },
	{ name: "Al Shindagha" },
	{ name: "Al Souq Al Kabeer" },
	{ name: "Al Twar First" },
	{ name: "Al Twar Second" },
	{ name: "Al Twar Third" },
	{ name: "Al Warqa'a Fifth" },
	{ name: "Al Warqa'a First" },
	{ name: "Al Warqa'a Fourth" },
	{ name: "Al Warqa'a Second" },
	{ name: "Al Warqa'a Third" },
	{ name: "Al Wasl" },
	{ name: "Al Waheda" },
	{ name: "Ayal Nasir" },
	{ name: "Aleyas" },
	{ name: "Bu Kadra" },
	{ name: "Dubai Investment park First" },
	{ name: "Dubai Investment Park Second" },
	{ name: "Emirates Hill First" },
	{ name: "Emirates Hill Second" },
	{ name: "Emirates Hill Third" },
	{ name: "Hatta" },
	{ name: "Hor Al Anz" },
	{ name: "Hor Al Anz East" },
	{ name: "Jebel Ali 1" },
	{ name: "Jebel Ali 2" },
	{ name: "Jebel Ali Industrial" },
	{ name: "Jebel Ali Palm" },
	{ name: "Jumeira First" },
	{ name: "Palm Jumeira" },
	{ name: "Jumeira Second" },
	{ name: "Jumeira Third" },
	{ name: "Al Mankhool" },
	{ name: "Marsa Dubai" },
	{ name: "Mirdif" },
	{ name: "Muhaisanah Fourth" },
	{ name: "Muhaisanah Second" },
	{ name: "Muhaisanah Third" },
	{ name: "Muhaisnah First" },
	{ name: "Al Mushrif" },
	{ name: "Nad Al Hammar" },
	{ name: "Nadd Al Shiba Fourth" },
	{ name: "Nadd Al Shiba Second" },
	{ name: "Nadd Al Shiba Third" },
	{ name: "Nad Shamma" },
	{ name: "Naif" },
	{ name: "Al Muteena First" },
	{ name: "Al Muteena Second" },
	{ name: "Al Nasr, Dubai" },
	{ name: "Port Saeed" },
	{ name: "Arabian Ranches" },
	{ name: "Ras Al Khor" },
	{ name: "Ras Al Khor Industrial First" },
	{ name: "Ras Al Khor Industrial Second" },
	{ name: "Ras Al Khor Industrial Third" },
	{ name: "Rigga Al Buteen" },
	{ name: "Trade Centre 1" },
	{ name: "Trade Centre 2" },
	{ name: "Umm Al Sheif" },
	{ name: "Umm Hurair First" },
	{ name: "Umm Hurair Second" },
	{ name: "Umm Ramool" },
	{ name: "Umm Suqeim First" },
	{ name: "Umm Suqeim Second" },
	{ name: "Umm Suqeim Third" },
	{ name: "Wadi Alamardi" },
	{ name: "Warsan First" },
	{ name: "Warsan Second" },
	{ name: "Za'abeel First" },
	{ name: "Za'abeel Second" },
];
export let HoursData = [1, 2, 3, 4, 5, 6, 7, 8];
export let bookingTime = [
	"13:00-14:00",
	"14:00-15:00",
	"15:00-16:00",
	"16:00-17:00",
	"15:00-18:00",
];
export const ServicesArray = [
	{
		name: "General Cleaning",
		slug: "/general-cleaning",
		img: process.env.REACT_APP_BASE_URL + "/Assets/vacuum.webp",
		price: "120",
		detail: {
			bannerImage:
				process.env.REACT_APP_BASE_URL + "/Assets/Banners/DetailsImage.webp",
			data: (
				<div>
					<p className="ContentTextInDetailPage">
						Getting your house cleaned in the uae is only a few pushes key to
						away. samira cleaning is here for all your starting place needs,
						from house cleaning to hair great meeting room for new-comers
						supports. House cleaning is a serious regular work, so you can let
						us do it to but for time and power for a given time.
					</p>
					<br />
					<br />
					<strong>
						Simple, Convenient, and Affordable Way to Book House Cleaning
						Services in UAE
					</strong>
					<br />
					<br />
					<p className="ContentTextInDetailPage">
						Making your life easier is simple with Justlife. Our services are
						here for you for every part of your life, and you won't have to
						worry about cleaning your house ever again. You can hire a house
						cleaning service from our website and mobile app with a few simple
						clicks. If you are looking for ahome cleaning service in the UAE,
						look no further! Here's why you should hire a professionalhouse
						cleaner and more details on the service.
					</p>
				</div>
			),
		},
	},
	{
		name: "Deep cleaning",
		slug: "/deep-cleaning",
		img: process.env.REACT_APP_BASE_URL + "/Assets/DeepCleaning.webp",
		price: "120",
		detail: {
			bannerImage:
				process.env.REACT_APP_BASE_URL + "/Assets/Banners/DeepCleaning.webp",
			data: (
				<div>
					<p className="ContentTextInDetailPage">
						Deep cleaning is a very much form of cleaning and is different from
						General cleaning or Home cleaning supports. While general cleaning
						is basic and necessarily a quick way of getting clean (dry) and
						dusting the house things, tops and floor or putting in order up the
						space, deep cleaning goes further and beyond the basic works to put
						out waste more getting much out dirt and bacteria 1, soap scum,
						dust, dirty marks and good-looking much any kind of dirt in kept
						secret areas 2 of your starting place or office. Home or office deep
						cleaning supports are offered by many companies in Dubai, but to the
						letter putting into effect of this support is usually underwhelming
						being in debt to existence without of most important necessary
						things. Deep cleaning has need of to do with industry degree
						necessary things and highly trained deep cleaning experts who get
						through knowledge of the little shades (of feeling, color, sense and
						so on) and details about the support putting into effect and most
						importantly, working well and good at producing an effect of use of
						deep cleaning necessary things and tactics
					</p>
					<br />
					<br />
					<strong>
						Simple, Convenient, and Affordable Way to Book House Cleaning
						Services in UAE
					</strong>
					<br />
					<br />
					<p className="ContentTextInDetailPage">
						We regard deep cleaning as a built-in part of our business and as
						such tell/give frequent training to our specialists. All our deep
						cleaning specialists i.e male and female staff are highly trained in
						using fancy (or smart) equipment like floor scrubbing machines with
						different kind of base pads to sparkle your floors. For instance,
						using a floor scrubbing machine needs/demands careful/very clean
						attention and craft to understand the kind of flooring and
						application of corresponding brush or polish pads dependent upon the
						type of flooring. This is where Pestzilla's team in Dubai hold a
						competitive edge over competition to skilfully apply deep cleaning
						ways of doing things. Whether it's an apartment, villa deep or
						office deep cleaning service in Dubai, Pestzilla's deep cleaning
						division are fully capable of delivering an (in a surprising and
						interesting way) clean property. Whether you require move in or move
						out deep cleaning services in Dubai, or (something commonly done)
						deep cleaning services for your apartment or villa, Pestzilla deep
						cleaning services can be your trusted partner in Dubai or Sharjah.
					</p>
				</div>
			),
		},
	},
	{
		name: "Carpet cleaning",
		slug: "/carpet-cleaning",
		img: process.env.REACT_APP_BASE_URL + "/Assets/CarpetCleaner.webp",
		price: "120",
		detail: {
			bannerImage:
				process.env.REACT_APP_BASE_URL + "/Assets/Banners/CarpetCleaning.webp",
			data: (
				<div>
					<p className="ContentTextInDetailPage">
						Carpets are intended to expand one of a kind kinds of cleaning
						issues. those carpets can get stinky and feature odors. So, everyday
						cleansing can assist to remove the bacteria and odors out of your
						carpet and help you to stay in a hygienic environment. We use the
						first-rate cleaning answers for you to dispose of the dirt and
						microbes out of your carpet and make it look all new. you may strive
						our carpet cleaning services in Dubai to make your carpet appearance
						all new.
					</p>
					<br />
					<br />
					<strong>
						Simple, Convenient, and Affordable Way to Book Carpet Cleaning
						Services in UAE
					</strong>
					<br />
					<br />
					<p className="ContentTextInDetailPage">
						Formulated detergents are applied to the carpet at once and are
						agitated with the help of a device. We use a vacuum cleaner to take
						out the purifier and make your carpet appear clean. The detergents
						that we use assist make your carpet scent fine and look properly.
					</p>
				</div>
			),
		},
	},
	{
		name: "Ironing",
		slug: "/ironing",
		img: process.env.REACT_APP_BASE_URL + "/Assets/Ironing.webp",
		price: "120",
		detail: {
			bannerImage:
				process.env.REACT_APP_BASE_URL + "/Assets/Banners/Ironing.webp",
			data: (
				<div>
					<p className="ContentTextInDetailPage">
						Exquisite carrier from this employer. clothes dry cleaned and back
						inside 24 hours, top notch activity achieved. There customer support
						is amazing too- I left a work pass on one of the objects and after
						an electronic mail trade they lower back it to me very quickly.
						exceedingly recommended.
					</p>
					<br />
					<br />
					<strong>
						Simple, Convenient, and Affordable Way to Book Ironing and folding
						clothes Cleaning Services in UAE
					</strong>
					<br />
					<br />
					<p className="ContentTextInDetailPage">
						Putting in the ironing board, looking ahead to the iron to warmness
						up, and punctiliously going over each inch of the garments to clear
						out any creases. The ironing technique calls for time and effort. At
						HomeSquad, the exceptional cleansing enterprise in Dubai, we've a
						talented team that looks after your ironing wishes! we can come to
						your property and make certain your garments are ironed and well put
						away simply where you want them! Our team will iron your clothes
						with perfection and ensure that the exclusive fabrics acquire the
						precise ironing treatment. Our specialists can iron something out of
						your shirts, bed sheets to desk garments and lead them to look
						faultless. With the maximum priority to purchaser delight, be
						assured of expert provider from our skilled and reliable
						professional maids in Dubai. ebook now or name us on 800 9876 to
						confirm your carrier.
					</p>
				</div>
			),
		},
	},
	{
		name: "Sofa Cleaning",
		slug: "/sofa-cleaning",
		img: process.env.REACT_APP_BASE_URL + "/Assets/SofaCleaning.webp",
		price: "120",
		detail: {
			bannerImage: process.env.REACT_APP_BASE_URL + "/Assets/Banners/Sofa.webp",
			data: (
				<div>
					<p className="ContentTextInDetailPage">
						Exquisite carrier from this employer. clothes dry cleaned and back
						inside 24 hours, top notch activity achieved. There customer support
						is amazing too- I left a work pass on one of the objects and after
						an electronic mail trade they lower back it to me very quickly.
						exceedingly recommended.
					</p>
					<br />
					<br />
					<strong>
						Simple, Convenient, and Affordable Way to Book Ironing and folding
						clothes Cleaning Services in UAE
					</strong>
					<br />
					<br />
					<p className="ContentTextInDetailPage">
						Putting in the ironing board, looking ahead to the iron to warmness
						up, and punctiliously going over each inch of the garments to clear
						out any creases. The ironing technique calls for time and effort. At
						HomeSquad, the exceptional cleansing enterprise in Dubai, we've a
						talented team that looks after your ironing wishes! we can come to
						your property and make certain your garments are ironed and well put
						away simply where you want them! Our team will iron your clothes
						with perfection and ensure that the exclusive fabrics acquire the
						precise ironing treatment. Our specialists can iron something out of
						your shirts, bed sheets to desk garments and lead them to look
						faultless. With the maximum priority to purchaser delight, be
						assured of expert provider from our skilled and reliable
						professional maids in Dubai. ebook now or name us on 800 9876 to
						confirm your carrier.
					</p>
				</div>
			),
		},
	},
];
export const RequestCallBack = [
	{
		heading: "10+ YEARS EXPERIENCE",
		detail:
			"Samira salem service is one of the leading regional incorporated centers management answers company within the center East. Its service specialties are unfold across integrated facilities control, waste control, and lots of greater. Samira salem service released its domestic pro maintenance offerings in 2009 as an one-of-a-kind carrier department to hold and keep houses within the UAE.Samira salem service has accrued a group of reliable home preservation specialists to decorate the value, life, and beauty of your house. Our 24/7 preservation carrier team takes care of your house so you can cognizance at the critical things in existence. Time is treasured and so is your private home. Samira salem service is here to serve you so you in no way ought to surrender on both of them.",
	},
	{
		heading: "Our records",
		detail:
			"Samira salem service renovation services in 2009 as an specific provider department to keep and keep houses inside the UAE.",
	},
	{
		heading: "Our challenge",
		detail:
			"To emerge as a pacesetter in top rate home protection services and solutions for the duration of the UAE with an first rate crew of professionals.",
	},
	{
		heading: "Our imaginative and prescient",
		detail:
			"Samira salem service dedicates itself to guaranteeing expert domestic preservation offerings through a dating of accept as true with, revel in, and reliability that continues to exceed purchaser expectations.",
	},
	{
		heading: "Samira salem service offerings",
		detail:
			"When it comes to remodeling, we do it all. We pride ourselves on our commitment to direct and honest service. Not to mention we never go over your budget unless you change the scope of work and our 5 year warranty ensures you have the best quality craftsmanship at home. No matter what home improvement project you dream of, the HOMEfix team can make it happen with ease.",
	},
	{
		heading:
			"while is a Samira salem service generally required to be known as in?",
		detail:
			"Samira salem service are typically required for all form of atypical jobs in the house, but, in the main for jobs require professional drilling into partitions and ceilings to grasp, mount or deploy fixtures. in addition, handyman additionally offer IKEA or other modular furniture assembly required upon a new purchase or after a pass.",
	},
	{
		heading:
			"What are a number of the things to be careful about whilst hiring a Samira salem service ?",
		detail:
			"while looking and hiring a Samira salem service in Dubai or UAE, it is essential to keep in mind the subsequent :1. The Samira salem service has the visa from a business enterprise with the perfect exchange license, with unique Samira salem service related classes, accepted via the financial development department.2. All essential insurances are in region – third birthday party liability, people' medical insurance, and employees’ compensation in your peace of mind.3. because the high-quality of the carrier is dependent on the person’s qualification, experience, mind-set, and communique competencies, it is important to have the Samira salem service, and no longer the company, checked.four. The Samira salem service is prompt and responds to consumer queries, either directly or directing to the proper professional individual in case unsure approximately the provider.Failure to conform with neighborhood rules, as mentioned above, can result in hefty fines for customers",
	},
	{
		heading: "Why do Samira salem service charge by the hour?",
		detail:
			"Samira salem service offerings do now not typically require an inspection and may be done right away e.g. mounting a tv, curtain, or blind installation if the components are available. It, therefore, does now not make experience to do an inspection and charge a name-out. consequently it is more obvious and honest to each parties, to observe a in keeping with hour charging technique.",
	},
	{
		heading:
			"What are commonplace accidents that could occur because of low-satisfactory Samira salem service services?",
		detail:
			"Even as hanging furniture, you may drill via a twine containment or a water pipe in the wall or drill the hollow too unfastened that the fixture isn’t firmly hung to the wall. also, the strength of the screw won't be appropriate to take the burden of the wall fixture making it liable to fall over time. also, the level of the wall fixture if no longer done well will cause a fall as weight of the fixture does not get balanced and remains hinged to one aspect. If proper cleanup isn't finished there could a nail that would hurt someone walking casually across the work place.",
	},
];

var today = new Date();
export var CurrentDate = parseInt(String(today.getDate()).padStart(0, "0"));
export let CurrentDateInWord = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
][new Date().getDay()];
const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
const d = new Date();
export let CurrentMonth = monthNames[d.getMonth()];
