import React, { useEffect, useContext } from "react";
import { Context } from "../../Context/Context";
import { Circles } from "react-loader-spinner";
import { CurrentMonth } from "../../Data";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTotalPrice } from "../../Reducer/Reducer";

const StepProgressRightSide = (props) => {
	const contextData = useContext(Context);
	const { search } = useLocation();
	const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
	const Dispatch = useDispatch();
	const {
		Duration,
		Material,
		serviceDetail,
		totalPrice,
		Service,
		Number_of_Professionals,
		DateNumber,
		DateName,
		Start_Time,
		ADDRESS,
	} = useSelector((state) => state);

	function handleTotalPrice() {
		let total = parseInt(urlParams.price);
		total = total * Duration;
		if (Material === "Yes,please") {
			total = total + 20;
		}
		serviceDetail.map((item, index) => {
			let res = parseInt(item.price) * parseInt(item.count);
			total = total + res;
		});
		// totalPrice = total;
		Dispatch(UpdateTotalPrice(total));
		return total;
	}
	console.log("first", serviceDetail);
	return (
		<div
			className="WidthRightSideStepProgressBar"
			style={{ width: props.Width ? "100%" : "" }}
		>
			<div className="bg-white outerWrapperRightSideStepProgressBar">
				{contextData.DisplayLoader && (
					<div
						className="d-flex justify-content-center align-items-center
        outerWrapperLoaderInResume"
					>
						<Circles height="90" width="90" color="white" ariaLabel="loading" />
					</div>
				)}
				<div className="PaddingRightSideStepProgressBar">
					<p className="labelHeaderRightSide">SERVICE DETAILS</p>
					{Service && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Service</p>
							<p className="innerLabel text-black textHolderRightSideServiceStepsProgress Textcapitalize">
								{Service}
							</p>
						</div>
					)}
					{serviceDetail.map((item, index) => {
						return (
							<div
								key={index}
								className="d-flex align-items-center justify-content-between"
							>
								<p className="serViceDetailInForm innerLabel">
									{item.count}x{item.des}
								</p>
								<p>AED {item.price * item.count}.00</p>
							</div>
						);
					})}
					{Duration && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Duration</p>
							<p className="innerLabel text-black">{Duration} Hours</p>
						</div>
					)}

					{Number_of_Professionals && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Number of Professionals</p>
							<p className="innerLabel text-black">{Number_of_Professionals}</p>
						</div>
					)}
					{Material && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Material</p>
							<p className="innerLabel text-black">{Material}</p>
						</div>
					)}
				</div>
				<div className="outerWrapperMidBoxInRightSideOfStepContentBox">
					<p className="labelHeaderRightSide">Date And Time</p>
					{DateNumber && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Date</p>
							<div>
								<p className="innerLabel text-black">{DateNumber}, </p>
								<p className="innerLabel text-black"> {CurrentMonth} </p>{" "}
								<p className="innerLabel text-black">{DateName}</p>
							</div>
						</div>
					)}
					{Start_Time && (
						<div className="d-flex align-items-center justify-content-between mt-2">
							<p className="innerLabel">Start Time</p>
							<p className="innerLabel text-black">{Start_Time}</p>
						</div>
					)}
				</div>
				<div className="outerWrapperMidBoxInRightSideOfStepContentBox BorderTopNone">
					<p className="labelHeaderRightSide">ADDRESS</p>
					<p className="innerLabel text-black mt-2">{ADDRESS}</p>
				</div>
				<div className="outerWrapperMidBoxInRightSideOfStepContentBox BorderTopNone BorderBottomNone">
					<p className="labelHeaderRightSide">PRICE DETAILS (INC. VAT)</p>
					<div className="d-flex align-items-center justify-content-between mt-2">
						<p className="innerLabel">Total Price</p>
						<p className="innerLabel TextHolderTotalPrice">
							AED {handleTotalPrice()}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepProgressRightSide;
