import React from "react";
import GeneralForm from "./GeneralForm";
const CarpetCleaningForm = () => {
	let CardArray = [
		{
			name: "deep Cleaning",
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/CarpetCleaning1.webp",
			des: {
				h1: "Small length as much as five meters",
				h2: "professional carpet cleansing to clean up the appearance of your property",
			},
			id: "c1",
			price: "200",
		},
		{
			name: "deep Cleaning",
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/CarpetCleaning2.webp",
			des: {
				h1: "Medium size up to 8 meters",
				h2: "contend with your carpet with everyday deep cleansing.",
			},
			id: "c2",
			price: "200",
		},
		{
			name: "deep Cleaning",
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/CarpetCleaning3.webp",
			des: {
				h1: "huge size up to 12 meters",
				h2: "perfectly wiped clean and in a modern day circumstance!",
			},
			id: "c3",
			price: "200",
		},
	];
	return (
		<div>
			<GeneralForm
				name="Carpet Cleaning"
				image={
					process.env.REACT_APP_BASE_URL +
					"/Assets/FormImages/CarpetCleaning.webp"
				}
				cardArray={CardArray}
			/>
		</div>
	);
};

export default CarpetCleaningForm;
