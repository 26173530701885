import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import { AiFillHome } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { ServicesArray } from "../Data";
const Details = () => {
	const [Heading, setHeading] = useState("");
	const [Price, setPrice] = useState("");
	const [ContentData, setContentData] = useState({
		bannerImage: "",
		data: "",
	});
	const location = useLocation();
	let Split = location.pathname.split("/");
	useEffect(() => {
		let title = Split[Split.length - 1];
		while (title.includes("-")) {
			title = title.replace("-", " ");
		}
		setHeading(title.toUpperCase());
		for (let i = 0; i < ServicesArray.length; i++) {
			if (ServicesArray[i].slug === "/" + Split[Split.length - 1]) {
				setContentData(ServicesArray[i].detail);
				setPrice(ServicesArray[i].price);
				break;
			}
		}
	}, [Split[Split.length - 1]]);
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-1">
				<div className="d-flex align-items-center gap-2 mt-2 mb-3">
					<Link
						onClick={() => {
							window.scrollTo(0, 0);
						}}
						className="text-decoration-none"
						to="/"
					>
						<AiFillHome className="iconsInDetailPage" />
					</Link>
					<FaAngleRight className="iconsInDetailPage mt-1" />
					<p className="mt-1 iconsInDetailPage">{Heading} IN UAE</p>
				</div>
				<div
					className="imageHolderDetailPage position-relative"
					style={{
						backgroundImage: `url(${ContentData.bannerImage})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
					}}
				>
					<div className="innerWrapperImageHolderDetailsPage">
						<div className="OuterContainerHeadingDetailImage">
							<h1 className="text-white headingDetailsPage">
								{Heading} IN UAE
							</h1>
							<p className="text-white mt-3 InnerHeadingDetailsPage">
								Now book the best affordable, simple and convenient house
								cleaning service.
							</p>
							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none HyperLinks LinksInHeaderNavbar"
								to={`/details/${
									Split[Split.length - 1]
								}/book-professional?price=${Price}`}
							>
								<Button className="BookProfessionalButtonInDetailPage mt-4">
									Book Now
								</Button>
							</Link>
						</div>
					</div>
				</div>
				<div className="pb-3">
					<h3 className="mt-5 mb-4 ThirdHeadingInDetailPage">
						{Heading.toLowerCase()} Services in UAE
					</h3>
					<br />
					{ContentData.data}
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default Details;
