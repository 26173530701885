import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import BookProfessional from "./Pages/BookProfessional";
import AboutUs from "./Pages/AboutUs";
import Faq from "./Pages/Faq";
import TermAndCondition from "./Pages/TermAndCondition";
import Privacy from "./Pages/Privacy";
import Details from "./Pages/Details";
import ContactUs from "./Pages/ContactUs";
import RequestACallBack from "./Pages/RequestACallBack";
import { ThankYouMessage } from "./Pages/ThankYouMessage";
function App() {
	return (
		<div>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/about-us" element={<AboutUs />} />
				<Route exact path="/contact-us" element={<ContactUs />} />
				<Route exact path="/faq" element={<Faq />} />
				<Route exact path="/thank-you-message" element={<ThankYouMessage />} />
				<Route
					exact
					path="/request-a-call-back"
					element={<RequestACallBack />}
				/>
				<Route
					exact
					path="/term-and-conditions"
					element={<TermAndCondition />}
				/>
				<Route exact path="/privacy" element={<Privacy />} />
				<Route
					exact
					path="/term-and-condition"
					element={<TermAndCondition />}
				/>
				<Route exact path="/details/:id" element={<Details />} />
				<Route
					exact
					path="/details/:id/book-professional"
					element={<BookProfessional />}
				/>
			</Routes>

			<div className="whatsappIcon position-fixed">
				<a
					href="https://web.whatsapp.com"
					rel="noopener"
					target="_blank"
					className="text-decoration-none text-white"
				>
					<img
						src={process.env.REACT_APP_BASE_URL + "/Assets/whatsapp.png"}
						alt="teamwork"
						style={{ width: "56px" }}
					/>
				</a>
			</div>
			<div className="PhoneIcon position-fixed">
				<a href="tel:052 54 58 045">
					<img
						src={process.env.REACT_APP_BASE_URL + "/Assets/phone.png"}
						alt="teamwork"
						style={{ width: "56px" }}
					/>
				</a>
			</div>
		</div>
	);
}

export default App;
