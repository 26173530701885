import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { ImWhatsapp } from "react-icons/im";
import { MdOutlineMailOutline } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import FormContentUs_CallBack from "../Component/FormContentUs_CallBack";
const ContactUs = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">
					Content Us
				</p>
				<Row className="mt-4">
					<Col>
						<div className="d-flex align-items-center gap-2 mb-4">
							<AiOutlineHome className="iconsContactUS" />
							<div>
								<h5>Office 204, Albraha area,</h5>
								<p className="ParagraphFeedBackBox">
									Jessco market building Dubai, UAE
								</p>
							</div>
						</div>
						<div className="d-flex align-items-center gap-2 mb-4">
							<FiPhone className="iconsContactUS" />
							<a
								href="tel:052 54 58 045"
								className="C-pointer  text-black  text-decoration-none"
							>
								<h5>052 54 58 045</h5>
							</a>
						</div>
						<div className="d-flex align-items-center gap-2 mb-4">
							<ImWhatsapp className="iconsContactUS" />
							<a
								href="tel:052 54 58 045"
								className="C-pointer  text-black  text-decoration-none"
							>
								<h5>052 54 58 045</h5>
							</a>
						</div>
						<div className="d-flex align-items-center gap-2 mb-4">
							<MdOutlineMailOutline className="iconsContactUS" />
							<h5 className="mb-1">Samiracleanplus@gmail.com</h5>
						</div>
					</Col>
					<Col>
						<FormContentUs_CallBack submitFormFrom="contact us" />
					</Col>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default ContactUs;
