import React from "react";
import GeneralForm from "./GeneralForm";
const SofaCleaningForm = () => {
	let CardArray = [
		{
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/SofaCleaning1.webp",
			des: {
				h1: "3 Seater - first-class seller",
				h2: "professional carpet cleansing to clean up the appearance of your property",
			},
			id: "s1",
			price: "200",
		},
		{
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/SofaCleaning2.webp",
			des: {
				h1: "5 seater",
				h2: "Eliminate all of the stains to your sofa in 50 mins",
			},
			id: "s2",
			price: "200",
		},
		{
			count: 0,
			img:
				process.env.REACT_APP_BASE_URL +
				"/Assets/FormImages/SofaCleaning3.webp",
			des: {
				h1: "Single Seat",
				h2: "Get a refreshed and new looking couch with the help of our experts! carrier applies for material and leather seats most effective.",
			},
			id: "s3",
			price: "200",
		},
	];
	return (
		<div>
			<GeneralForm
				name="Sofa Cleaning"
				image={
					process.env.REACT_APP_BASE_URL +
					"/Assets/FormImages/SofaCleaning.webp"
				}
				cardArray={CardArray}
			/>
		</div>
	);
};

export default SofaCleaningForm;
