import React, { useState, useContext } from "react";
import { Context } from "../Context/Context";
import { useDispatch } from "react-redux";
import {
	UpdateDuration,
	UpdateNumberOfMaterial,
	UpdateNumberOfProfessional,
	UpdateDateName,
	UpdateDateNumber,
	UpdateStart_Time,
} from "../Reducer/Reducer";
const SelectNumber = (props) => {
	const [SelectingIndex, setSelectingIndex] = useState(0);
	const contextData = useContext(Context);
	const Dispatch = useDispatch();
	return (
		<div className="d-flex  align-item-center flex-wrap gap-md-3 gap-1">
			{props.numberOfArray.map((item, index) => {
				let selected = false;
				if (index === SelectingIndex) {
					selected = true;
				}
				return (
					<div key={index} className="d-flex flex-column align-items-center">
						<p className="labelDateInStepProgressBar">
							{item.name.substring(0, 3).toUpperCase()}
						</p>
						<div
							className="outerWrapperSelectNumber"
							key={index}
							style={{
								backgroundColor: selected ? "#54CAFF" : "",
								color: selected ? "white" : "",
								borderColor: selected ? "#a8a8a8" : "",
							}}
							onClick={() => {
								setSelectingIndex(index);
								if (props.service === "hours") {
									Dispatch(UpdateDuration(item.number));
								}
								if (props.service === "material") {
									Dispatch(UpdateNumberOfMaterial(item.number));
								}
								if (props.service === "professional") {
									Dispatch(UpdateNumberOfProfessional(item.number));
								}
								if (props.service === "date") {
									Dispatch(UpdateDateNumber(item.number));
									Dispatch(UpdateDateName(item.name));
								}

								if (props.service === "time") {
									props.HandlerSetTime(item.number);
									Dispatch(UpdateStart_Time(item.number));
								}
							}}
						>
							{item.number}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SelectNumber;
