import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

const AboutUs = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer textHolderPageUpperHeading">
					Home
				</p>
				<p className="text-center textHolderPageUnderHeading mb-3">About Us</p>
				<div className="ContentBox">
					<p>
						Samira salem service was hooked up in 2005 after a consummate
						research with the aid of a squad of profoundly eligible experts
						owning a wealth of revel in inside the subject of maid services. 15
						years of revel in has driven us to show right into a pioneer in
						offering skilled, professional and sincere maids, who are nicely
						trained to perform residential and business cleansing with an
						extremely good wellknown. We began our journey with simply 10 group
						of workers and today we're glad to mention that we owe about one
						hundred fifty maids and about 15 drivers and vehicles to supply the
						nice pleasant offerings to our customers. With a verified record of
						best and integrity, Samira salem service covers today nearly all
						essential parts of Dubai. we've got followed a systematic
						pinnacle-down approach for fulfilling our customers want, making
						sure first-rate and consistency day-in, day-out. Likewise, with the
						gargantuan experience that our maids have received over years, they
						know to do their responsibility brief and well. Our willpower to
						supplying dependable and expert carrier has driven us to earn an ISO
						9001:2008- quality control system certification. We intention to
						meet all of our clients by way of offering professional offerings
						that our customers can rely upon. Our carrier widespread is
						constructed at the engine of delivering high quality provider. With
						the notion that purchaser is constantly a king, we endeavor to offer
						paintings schedules as consistent with our consumer's request.
						moreover, we take unique effort to offer full aid in case you
						request for rescheduling in the route of an emergency. All our maids
						are employed immediately by us after an intensive history
						verification to make certain evidence of identification so that our
						customers can sense secure approximately who plays their services.
					</p>
					<br />
					<br />
					<strong>Our Vision</strong>
					<p>
						Samira salem service could be regarded as the foremost predilection
						of every organization and each student in terms of satisfactory,
						truthful and dependable carrier. Our recognition will usually be in
						serving you with the maximum devoted, dependable and professional
						cleaning personnel.
					</p>
					<br />
					<br />
					<strong>Our Mission</strong>
					<p>
						Our project is to make every residential and industrial constructing
						in UAE a easy and worth location for living. we will be always
						striving difficult to offer exceptional domestic cleansing services
						to all of the residents of Dubai at an low priced price, making sure
						a hundred % client satisfaction.
					</p>
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default AboutUs;
