import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Button, Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
export const HeaderInitialValues = {
	name: "",
	phone: "",
	email: "",
	location: "",
	instruction: "",
	suggestion: "",
};
const FormContentUs_CallBack = (props) => {
	const [values, setValues] = useState(HeaderInitialValues);
	const [validated, setValidated] = useState(false);
	const [Phone, setPhone] = useState();
	const navigate = useNavigate();
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};
	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			event.preventDefault();
			emailjs
				.sendForm(
					"service_m7qeuhh",
					"template_vfvjvyg",
					event.target,
					"O8x20B2okyS1wHgXo"
				)
				.then(
					(result) => {
						console.log(result.text);
					},
					(error) => {
						console.log(error.text);
					}
				);
			navigate("/thank-you-message");
		}
		setValidated(true);
	};
	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<div className={`mb-3`}>
				<div className={"w-100"}>
					<Form.Group className="mb-3" controlId="validationCustom01">
						<label htmlFor="phone" className="Label">
							Name:
						</label>
						<Form.Control
							required
							type="text"
							name="name"
							value={values.name}
							onChange={handleInputChange}
							placeholder="You Name"
							className="FormsInputFieldsDatePicker"
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3" controlId="validationCustom02">
						<label htmlFor="phone" className="Label">
							Email:
						</label>
						<Form.Control
							required
							type="email"
							placeholder="Email"
							name="email"
							value={values.email}
							onChange={handleInputChange}
							className="FormsInputFieldsDatePicker"
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
					</Form.Group>
					<div className="d-flex flex-column mb-3  pb-2">
						<label htmlFor="phone" className="Label">
							Phone:
						</label>
						<PhoneInput
							defaultCountry="AE"
							placeholder="Enter phone number"
							className="FormsInputFieldsDatePicker"
							value={Phone}
							onChange={(value) => {
								setPhone(value);
							}}
						/>
					</div>
				</div>
				<div>
					<Form.Group controlId="validationCustom03">
						<label htmlFor="phone" className="Label">
							Instruction:
						</label>
						<Form.Control
							as="textarea"
							placeholder="Instruction..."
							name="instruction"
							value={values.instruction}
							onChange={handleInputChange}
							className="FormsInputFieldsDatePicker"
						/>
					</Form.Group>
					<div className="d-none">
						<textarea
							name="message"
							value={`Name:           ${values.name} 
								      Email:          ${values.email} 
											Phone:          ${Phone} 
                      Message:        ${values.instruction} 
											SubmittedFrom:  ${props.submitFormFrom}
														`}
							onChange={() => {
								console.log("Onchange");
							}}
						/>
					</div>
					<Button
						className="w-100 BookServiceButtons mt-3 BackGroundColor"
						type="submit"
					>
						Submit
					</Button>
				</div>
			</div>
		</Form>
	);
};
export default FormContentUs_CallBack;
