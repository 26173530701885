import React, { useState } from "react";
import Cards from "./Cards";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const GeneralForm = (props) => {
	const [loading, setLoading] = useState(true);

	const [List, setList] = useState(props.cardArray);

	return (
		<div>
			<h2 className="FormHeading LabelInStepsProgressFormLeftSide">
				{props.name}
			</h2>

			{loading && <Skeleton />}
			<div
				className="outerWrapperFormImage mb-3"
				style={{ display: loading ? "none" : "flex" }}
			>
				<img
					src={props.image}
					className="ImagesHolder  border-radius"
					alt="deepCleaning"
					onLoad={() => {
						setLoading(false);
					}}
				/>
			</div>

			{List.map((item, index) => {
				return (
					<div key={index}>
						<Cards
							item={item}
							lastIndex={List.length - 1}
							index={index}
							setList={setList}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default GeneralForm;
