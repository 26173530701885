import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ServicesArray } from "../Data";
const Services = () => {
	return (
		<Container className="outerWrapperServiceSection pb-5">
			<h1 className="text-center headingServiceSection">
				Leave your to-do list to us!
			</h1>
			<p className="text-center innerHeadingServiceSection">
				Take a look at out a number of our pinnacle domestic services:
			</p>
			<div className="d-flex flex-wrap justify-content-center w-100 gap-5 mt-4">
				{ServicesArray.map((item, index) => {
					return (
						<div className="outerWrapperServiceBox" key={index}>
							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none HyperLinks LinksInHeaderNavbar"
								to={`/details${item.slug}`}
							>
								<div className="ServiceBox">
									<div className="OuterWrapperServiceImage">
										<img
											src={item.img}
											alt="HomeCleaning"
											className="ImagesHolder"
										/>
									</div>
								</div>
							</Link>
							<p className="text-center LabelService">{item.name}</p>
						</div>
					);
				})}
			</div>
		</Container>
	);
};

export default Services;
