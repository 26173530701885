import React from "react";
import { Container } from "react-bootstrap";

const ReasonToLoveService = () => {
	return (
		<div className="bg-grey pb-5">
			<Container className="outerWrapperServiceSection pb-5">
				<h1 className="text-center headingServiceSection">
					There are so many reasons to love Samira salem cleaning services!
				</h1>
				<p className="text-center innerHeadingServiceSection">
					Here are the top 4!
				</p>
				<div className="d-flex mt-5 outerWrapperTriangleBoxesInReasonToLoveService flex-wrap">
					<div className="ReasonToLoveBox">
						<div className="outerWrapperImageHolderInReasonToLoveService">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/Star.webp"}
								alt="Star"
								className="ImagesHolder"
							/>
						</div>
						<p className="headingBoxReasonToLove">Top rated professionals</p>
						<p className="innerHeadingBox1ReasonToLove">
							Our professionals are reliable & well-trained, with an average
							rating of 4.78 out of 5!
						</p>
					</div>
					<div className="ReasonToLoveBox">
						<div className="outerWrapperImageHolderInReasonToLoveService">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/Calender.webp"}
								alt="Star"
								className="ImagesHolder"
							/>
						</div>
						<p className="headingBoxReasonToLove">Same-day availability</p>
						<p className="innerHeadingBox2ReasonToLove">
							Book in less than 60 seconds, and even select same-day slots.
						</p>
					</div>
					<div className="ReasonToLoveBox">
						<div className="outerWrapperImageHolderInReasonToLoveService">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/top.webp"}
								alt="Star"
								className="ImagesHolder"
							/>
						</div>
						<p className="headingBoxReasonToLove">
							Top quality, value for money services
						</p>
						<p className="innerHeadingBox3ReasonToLove">
							Our professionals are equipped with the best tools and our
							services are always priced with you in mind.
						</p>
					</div>
					<div className="ReasonToLoveBox">
						<div className="outerWrapperImageHolderInReasonToLoveService">
							<img
								src={process.env.REACT_APP_BASE_URL + "/Assets/smartphone.webp"}
								alt="Star"
								className="ImagesHolder"
							/>
						</div>
						<p className="headingBoxReasonToLove">Super app</p>
						<p className="innerHeadingBox4ReasonToLove">
							Being a super app method we’ve got the widest range of domestic
							services, so we’ve got you protected!
						</p>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default ReasonToLoveService;
