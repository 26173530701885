import React, { useState, useContext, useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { UpdateServiceDetail } from "../../Reducer/Reducer";
const Cards = (props) => {
	const [loading, setLoading] = useState(true);
	const { serviceDetail } = useSelector((state) => state);
	const Dispatch = useDispatch();
	return (
		<div
			className={`d-flex align-items-center gap-3 
      ${props.lastIndex === props.index ? "" : "pb-2"}
      ${props.lastIndex === props.index ? "" : "mb-2"} 
      `}
			style={{
				borderBottom:
					props.lastIndex === props.index ? "" : "1px solid rgb(213 212 212)",
			}}
		>
			{props.item.img && (
				<div
					className="outerWrapperCardImage"
					style={{ display: loading ? "none" : "flex" }}
				>
					<div>{loading && <Skeleton width={100} />}</div>
					<img
						src={props.item?.img}
						className="ImagesHolder border-radius"
						alt="cardImage"
						key={props.item?.img}
						style={{ display: loading ? "none" : "flex" }}
						onLoad={() => {
							setLoading(false);
						}}
					/>
				</div>
			)}
			<div className="w-100">
				<div className="d-flex align-items-center gap-1">
					{props.item.count > 0 && (
						<div className="d-flex align-items-center gap-1">
							<p className="innerLabel ColorLabel">
								{props.item.count}
								<IoMdClose />
							</p>
						</div>
					)}
					<p className="InnerHeadingDetailsPage FontWeight">
						{props.item.des.h1}
					</p>
				</div>
				<p className="InnerHeadingCards">{props.item.des.h2}</p>
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center gap-1">
						<p className="InnerHeadingCards SecondaryColor">
							AED {props.item.price}
						</p>
					</div>
					<div className="mt-4">
						{props.item.count > 0 ? (
							<div className="d-flex align-items-center BorderColor outerWrapperIncrementDecrementButton">
								<FiMinus
									className="iconPlus Color pe-1"
									onClick={() => {
										props.setList((list) => {
											if (list === undefined) return list;
											list[props.index].count--;
											return list;
										});
										let temp = [];
										temp = [...serviceDetail];
										console.log("first", temp);
										let index = temp.findIndex((x) => x.id === props.item.id);
										if (props.item.count > 0) {
											let updatedItem = {
												...temp[index],
												count: temp[index].count - 1,
											};
											temp[index] = updatedItem;
										} else {
											temp.splice(index, 1);
										}
										Dispatch(UpdateServiceDetail(temp));
									}}
								/>

								<p className="outingInForm">{props.item.count}</p>
								<BsPlus
									className="iconPlus Color ps-1"
									onClick={() => {
										props.setList((list) => {
											if (list === undefined) return list;
											list[props.index].count++;
											return list;
										});
										let temp = [];
										temp = [...serviceDetail];
										let index = temp.findIndex((x) => x.id === props.item.id);
										if (index !== -1) {
											let updatedItem = {
												...temp[index],
												count: temp[index].count + 1,
											};
											temp[index] = updatedItem;
											Dispatch(UpdateServiceDetail(temp));
										}
									}}
								/>
							</div>
						) : (
							<div
								className="d-flex align-items-center outerWrapperIncrementDecrementButton backColorIncrementDecrementColor"
								onClick={() => {
									let temp = [];
									temp = [...serviceDetail];
									temp.push({
										des: props.item.des.h1,
										count: 1,
										id: props.item.id,
										price: props.item.price,
									});
									Dispatch(UpdateServiceDetail(temp));
									props.setList((list) => {
										if (list === undefined) return list;
										list[props.index].count++;
										return list;
									});
								}}
							>
								<p className="AddButtonInForm">ADD</p>
								<BsPlus className="iconPlus" />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cards;
