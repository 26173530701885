import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

export const ThankYouMessage = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<div>
					<div
						className=" text-center w-100 pt-4 flex-nowrap justify-content-center align-items-center Gap FontWeight"
						style={{ fontSize: "45px" }}
					>
						<p className="text-center cursor-pointer textHolderPageUpperHeading">
							Home
						</p>
						<p className="text-center textHolderPageUnderHeading mb-3">
							Thank You
						</p>
					</div>
					<div className="d-flex justify-content-center">
						<img
							src={process.env.REACT_APP_BASE_URL + "/Assets/tikMark.webp"}
							alt="tikMark"
							className="WidthTikMarkImageInThankyou"
						/>
					</div>
					<Container>
						<p
							className="pt-3 pb-3"
							style={{ fontSize: "18px", color: "#555" }}
						>
							Thank You for your enquiry. We will be in touch shortly.” Really
							doesn’t cut it for me. And yet, that’s the best that the majority
							of websites I spend my valuable time visiting come up with. These
							throw away lines of copy don’t inspire me to continue doing
							business with the company and most often this page is a real
							disconnect from the rest of the website experience and the values
							of the company itself
						</p>
						<div className="d-flex justify-content-center pb-3">
							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none text-white w-25"
								to="/"
							>
								<div className="BookServiceButtons BackGroundColor text-center">
									OK
								</div>
							</Link>
						</div>
					</Container>
				</div>
			</Container>
			<Footer />
		</div>
	);
};
