import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import FormContentUs_CallBack from "../Component/FormContentUs_CallBack";
import Header from "../Component/Header";
import { RequestCallBack } from "../Data";

const RequestACallBack = () => {
	return (
		<div>
			<Header />
			<Container className="pt-3 pb-3">
				<p className="text-center cursor-pointer">Home</p>
				<p className="text-center textHolderPageUnderHeading mb-3">
					Request A Call Back
				</p>
				<div className="OuterContainerRequestCallBack" style={{ gap: "35px" }}>
					<div className="OuterWrapperFormRequestCallBack">
						<FormContentUs_CallBack submitFormFrom="contact us" />
					</div>
					<div className="OuterWrapperContentRequestCallBack">
						{RequestCallBack.map((item, index) => {
							return (
								<div key={index}>
									<p className="ParagraphFeedBackBox">
										<span className="labelAboutUs">{item.heading}: </span>
										{item.detail}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default RequestACallBack;
