import { createReducer, createSlice, createAsyncThunk } from "@reduxjs/toolkit";

let initialState = {
	Service: "",
	Duration: "",
	Number_of_Professionals: "",
	Material: "",
	DateNumber: "",
	DateName: "",
	Start_Time: "",
	ADDRESS: "",
	serviceDetail: [],
	suggestion: "",
	totalPrice: "",
};

const userReducer = createSlice({
	name: "user",
	initialState,
	reducers: {
		UpdateService(state, action) {
			state.Service = action.payload;
		},
		UpdateDuration(state, action) {
			state.Duration = action.payload;
		},
		UpdateNumberOfProfessional(state, action) {
			state.Number_of_Professionals = action.payload;
		},
		UpdateNumberOfMaterial(state, action) {
			state.Material = action.payload;
		},
		UpdateDateNumber(state, action) {
			state.DateNumber = action.payload;
		},
		UpdateDateName(state, action) {
			state.DateName = action.payload;
		},
		UpdateStart_Time(state, action) {
			state.Start_Time = action.payload;
		},
		UpdateADDRESS(state, action) {
			state.ADDRESS = action.payload;
		},
		UpdateServiceDetail(state, action) {
			state.serviceDetail = action.payload;
		},
		UpdateSuggestion(state, action) {
			state.suggestion = action.payload;
		},
		UpdateTotalPrice(state, action) {
			state.totalPrice = action.payload;
		},
	},
	// extraReducers: (builder) => {
	// 	builder.addCase(fetchUserName.fulfilled, (state, action) => {
	// 		state.name = action.payload;
	// 		state.loading = false;
	// 	});
	// 	builder.addCase(fetchUserName.pending, (state, action) => {
	// 		state.loading = true;
	// 		state.error = null;
	// 	});
	// 	builder.addCase(fetchUserName.rejected, (state, action) => {
	// 		state.loading = false;
	// 		state.error = action.meta.requestStatus;
	// 	});
	// },
});

export const {
	UpdateService,
	UpdateTotalPrice,
	UpdateSuggestion,
	UpdateServiceDetail,
	UpdateADDRESS,
	UpdateStart_Time,
	UpdateDateName,
	UpdateDateNumber,
	UpdateNumberOfMaterial,
	UpdateNumberOfProfessional,
	UpdateDuration,
} = userReducer.actions;

export default userReducer.reducer;
