import React, { useContext, useRef, useState } from "react";
import { Context } from "../../Context/Context";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import Step1Content from "./Step1Content";
import Step2Content from "./Step2Content";
import { useNavigate } from "react-router-dom";
import StepProgressRightSide from "./StepProgressRightSide";
import emailjs from "emailjs-com";
import { useSelector } from "react-redux";
const StepProgressForm = () => {
	const contextData = useContext(Context);
	const navigate = useNavigate();
	const form = useRef();
	const childRef = useRef();
	const {
		Service,
		Duration,
		Number_of_Professionals,
		Material,
		DateNumber,
		DateName,
		Start_Time,
		ADDRESS,
		// serviceDetail: [],
		suggestion,
		totalPrice,
	} = useSelector((state) => state);
	function step1Validator() {
		window.scrollTo(0, 0);
		return true;
	}

	function step2Validator() {
		return childRef.current.getAlert();
	}

	function step3Validator() {
		window.scrollTo(0, 0);
		return true;
	}
	function onFormSubmit() {
		emailjs
			.sendForm(
				"service_m7qeuhh",
				"template_vfvjvyg",
				form.current,
				"O8x20B2okyS1wHgXo"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		navigate("/thank-you-message");
		window.scrollTo(0, 0);
		localStorage.clear();
	}

	return (
		<div>
			<StepProgressBar
				startingStep={0}
				onSubmit={onFormSubmit}
				buttonWrapperClass="buttonWrapperClass"
				primaryBtnClass="primaryBtnClass"
				secondaryBtnClass="secondaryBtnClass"
				previousBtnName="BACK"
				nextBtnName="NEXT"
				steps={[
					{
						label: "Service Deal",
						name: "step 1",
						content: <Step1Content />,
						validator: step1Validator,
					},
					{
						label: "Date And Time",
						name: "step 2",
						content: <Step2Content ref={childRef} />,
						validator: step2Validator,
					},
					{
						label: "Check Out",
						name: "step 3",
						content: <StepProgressRightSide Width={true} />,
						validator: step3Validator,
					},
				]}
			/>
			<form ref={form}>
				<div className="d-none">
					<textarea
						name="message"
						value={`
						service:        ${Service} 
						address:        ${ADDRESS}     
						professional:   ${Number_of_Professionals}      
						hours:          ${Duration}
						material:       ${Material}
						date:           ${DateNumber}
						                ${""}${DateName}
						time:           ${Start_Time}
						instructions:   ${suggestion}
						TotalPrice:     ${totalPrice}
						SubmittedFrom:  ${" Book Professional"}
														`}
						onChange={() => {
							console.log("Onchange");
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default StepProgressForm;
