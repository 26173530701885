import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { Link } from "react-router-dom";
const Header = (props) => {
	return (
		<div>
			<div className="bg-black pt-2 pb-2">
				<Container
					className="
				 outerWrapperHeaderSocial
				d-flex justify-content-between
				  align-items-center text-white"
				>
					<div className="d-flex align-items-center gap-2">
						<a
							href="https://www.instagram.com/"
							rel="noopener"
							target="_blank"
							className="text-decoration-none text-white HoverTextColor"
						>
							<BsInstagram />
						</a>
						<a
							href="https://www.facebook.com/"
							rel="noopener"
							target="_blank"
							className="text-decoration-none text-white HoverTextColor"
						>
							<FaFacebookF />
						</a>
						<a
							href="https://web.whatsapp.com"
							rel="noopener"
							target="_blank"
							className="text-decoration-none text-white HoverTextColor"
						>
							<ImWhatsapp />
						</a>
					</div>
					<div className="d-flex align-items-center justify-content-end flex-wrap gap-2">
						<a
							href="tel:052 54 58 045"
							className="C-pointer text-white HoverTextColor text-decoration-none"
						>
							<p>052 54 58 045</p>
						</a>
						<a
							href="https://mail.google.com/mail/?view=cm&fs=1&to=info@justsamiraclean.com"
							className="text-white HoverTextColor text-decoration-none"
						>
							<p>info@justsamiraclean.com</p>
						</a>
					</div>
				</Container>
			</div>
			<Navbar collapseOnSelect expand="lg" className="bg-white">
				<Container>
					<Link
						onClick={() => {
							window.scrollTo(0, 0);
						}}
						className="text-decoration-none HyperLinks LinksInHeaderNavbar"
						to="/"
					>
						<img
							src={process.env.REACT_APP_BASE_URL + "/Assets/Logo.webp"}
							alt="logo"
							className="LogoPicHolder"
						/>
					</Link>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto"></Nav>
						<Nav className="d-flex gap-3">
							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none OuterWrapperHyperLinks"
								to="/"
							>
								<div className="HyperLinks LinksInHeaderNavbar">Home</div>
							</Link>
							<Link
								onClick={() => {
									props.executeScroll();
								}}
								className="text-decoration-none OuterWrapperHyperLinks "
								to="/#services"
							>
								<div className="HyperLinks LinksInHeaderNavbar">Services</div>
							</Link>

							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="OuterWrapperHyperLinks text-decoration-none "
								to="/details/general-cleaning/book-professional?price=120"
							>
								<div className=" HyperLinks LinksInHeaderNavbar ">
									Instant Booking
								</div>
							</Link>
							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none OuterWrapperHyperLinks "
								to="/contact-us"
							>
								<div className="HyperLinks LinksInHeaderNavbar ">
									Contact Us
								</div>
							</Link>

							<Link
								onClick={() => {
									window.scrollTo(0, 0);
								}}
								className="text-decoration-none OuterWrapperHyperLinks"
								to="/about-us"
							>
								<div className="HyperLinks LinksInHeaderNavbar">About Us</div>
							</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default Header;
